
import { Vue, Component } from 'vue-property-decorator';
import '@/styles/variables.less';
import { HEADTOP, SCONTIANER, INDUSTYR } from '@/utils/v1.2data.js';
@Component
export default class HeadTop extends Vue {
    showDetail = false;
    isHoverFirst = true;
    isHoverOther = false;
    currentTab = 0;
    isPlaying = false;
    bannervideo: any = null;
    fullVideo: any = null;
    swiperTimer: any = null;
    interval: any = 6000;
    actIndex = 0;
    publicPath = process.env.APP_VUE_CDN;
    industry = INDUSTYR;
    scenarioItems = [];
    sContainer = SCONTIANER;
    sourcetxt = HEADTOP;
    timer = null;
    mounted() {
        this.openSetInterval();
        this.bannervideo = document.querySelector('#bannervideo');
        let scenarioItems = document.querySelectorAll('.scenarioItem');
        this.scenarioItems = Array.from(scenarioItems);
        this.scenarioItems[0].classList.add('hover');

        document.addEventListener('fullscreenchange', () => {
            if (this.checkIsFullScreen()) {
                console.log('进入全屏');
                this.fullVideo.currentTime = 0;
                this.fullVideo.play();
            } else {
                console.log('退出全屏');
                this.fullVideo.muted = true;
                this.fullVideo.play();
            }
        });
    }
    beforeDestroy() {
        clearInterval(this.swiperTimer);
        clearInterval(this.timer);
    }
    swiperPlay() {
        if (this.currentTab < 2) {
            this.currentTab = this.currentTab + 1;
        } else {
            this.currentTab = 0;
        }
        if (this.currentTab == 1) {
            this.bannervideo.currentTime = 0;
        }
    }
    checkIsFullScreen() {
        var isFullScreen =
            document.fullscreen || (document as any).mozFullScreen || (document as any).webkitIsFullScreen;
        return isFullScreen == undefined ? false : isFullScreen;
    }
    openAffix() {
        this.$emit('openAffix');
    }
    handleFullScreen(e) {
        let fullVideo: any = document.querySelector('.fullVideo');
        fullVideo.muted = false;
        this.fullVideo = fullVideo;
        fullVideo.requestFullscreen();
    }
    openSetInterval() {
        if (this.swiperTimer) clearInterval(this.swiperTimer);
        this.swiperTimer = setInterval(() => {
            this.swiperPlay();
        }, this.interval);
    }
    next() {
        this.swiperPlay();
        this.openSetInterval();
    }
    onmouseenter(i) {
        clearInterval(this.timer);
        this.timer = setTimeout(() => {
            this.scenarioItems.forEach((dom, idx) => {
                if (i == idx) {
                    dom.classList.add('hover');
                } else {
                    dom.classList.remove('hover');
                }
            });
        }, 300);
    }
}
