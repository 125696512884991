
import { Vue, Component } from 'vue-property-decorator';
import HeadBox from '@/components/common/headTop.vue';
import FootBox from '@/components/common/footBox.vue';
import SwiperCard from '@/components/common/swiperCard.vue';
import BarnchScroll from '@/components/common/barnchScroll.vue';
import ContactAffix from '@/components/common/contactAffix.vue';

@Component({
    components: {
        HeadBox,
        FootBox,
        SwiperCard,
        BarnchScroll,
        ContactAffix,
    },
})
export default class Home extends Vue {
    openAffix() {
        (this.$refs.ContactAffix as any).openAffix();
    }
}
