<template>
    <div class="cutom-swiper">
        <!-- 品牌墙 -->
        <span class="main_s-t main_s-t-cor">合作客户</span>
        <div class="branchbox">
            <div class="branch1box">
                <img src="@/assets/v2/1.png" class="branchclass branchclass1 img1" />
                <img src="@/assets/v2/1.png" class="branchclass branchclass1" />
            </div>
            <div class="branch2box">
                <img src="@/assets/v2/2.png" class="branchclass branchclass1 img1" />
                <img src="@/assets/v2/2.png" class="branchclass branchclass1" />
            </div>
            <div class="branch1box">
                <img src="@/assets/v2/3.png" class="branchclass branchclass1 img1" />
                <img src="@/assets/v2/3.png" class="branchclass branchclass1" />
            </div>
            <div class="branch2box">
                <img src="@/assets/v2/4.png" class="branchclass branchclass1 img1" />
                <img src="@/assets/v2/4.png" class="branchclass branchclass1" />
            </div>
        </div>
    </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
@Component({
    components: {},
})
export default class Home extends Vue {
    branchbox = null;

    mounted() {
        this.branchbox = document.querySelector('.branchbox');
        this.creatKeyframes(2605, 'branch1scroll');
        this.creatKeyframes1(-2605, 'branch2scroll');
    }

    creatKeyframes(number, name = 'scroll') {
        // js创建@keyframes，ball从定位在(10,10)的位置运动到(100,100) 的位置
        const runkeyframes = ` 
          @keyframes ${name} {
            0% {
              transform: translateX(0px);
            }
            100% {
              transform: translateX(-${number}px);
            }
          }
          `;
        // 创建style标签
        const style = document.createElement('style');
        // 设置style属性
        style.type = 'text/css';
        // 将 keyframes样式写入style内
        style.innerHTML = runkeyframes;
        // 将style样式存放到head标签
        document.querySelector('head').appendChild(style);
    }
    creatKeyframes1(number, name = 'scroll') {
        // js创建@keyframes，ball从定位在(10,10)的位置运动到(100,100) 的位置
        const runkeyframes = ` 
          @keyframes ${name} {
            0% {
              transform: translateX(${number}px);
            }
            100% {
              transform: translateX(0px);
            }
          }
          `;
        // 创建style标签
        const style = document.createElement('style');
        // 设置style属性
        style.type = 'text/css';
        // 将 keyframes样式写入style内
        style.innerHTML = runkeyframes;
        // 将style样式存放到head标签
        document.querySelector('head').appendChild(style);
    }
}
</script>

<style lang="less" scoped>
span.main_s-t {
    display: block;
    font-weight: 600;
    color: #2a2e32;
    font-size: 40px;
    background-clip: text;
    background-image: radial-gradient(circle 300px, rgb(0, 0, 0), rgba(255, 255, 255, 0.6));
    width: 374px;
    color: transparent;
    -webkit-background-clip: text;
    margin: 0 auto;
    margin-top: 100px;
    &.main_s-t-cor {
        width: 170px;
        margin-top: 130px;
        margin-bottom: 60px;
        background-image: radial-gradient(circle 110px, #000000, rgba(255, 255, 255, 0.6));
    }
}
.branch1box {
    display: flex;
    animation: branch1scroll 100s linear infinite normal;
}
.branch2box {
    display: flex;
    animation: branch2scroll 120s linear infinite normal;
    margin: 40px 0;
}
.branchclass {
    display: inline-block;
    height: 117px;
}
.branchbox {
    width: 100%;
    overflow: hidden;
}
</style>
