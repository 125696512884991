<template>
    <div class="contactAffix">
        <div class="aniclass" @click="openAffix">
            <img src="@/assets/v2/tell2.svg" :style="{ opacity: isOPen ? 1 : 0 }" class="open" />
        </div>

        <div class="phoneClass" :style="{ display: isOPen ? 'block' : 'none' }">
            <div class="phoneClass-top">
                <span>联系我们</span>
                <span @click="onAffixReset">收起</span>
            </div>
            <div class="videobox">
                <video
                    src="https://cdn.aiadtech.com/official/20240226112338/idle.mp4"
                    autoplay
                    muted
                    loop
                    class="idle"
                ></video>
                <video
                    ref="talk1"
                    class="talkclass"
                    src="https://cdn.aiadtech.com/official/20240226112338/talk1.mp4"
                    :style="{ opacity: talkStatus == 1 && type == 1 ? 1 : 0 }"
                ></video>
                <video
                    ref="talk2"
                    class="talkclass"
                    src="@/assets/v2/video/talk3.mp4"
                    :style="{ opacity: talkStatus == 1 && type == 2 ? 1 : 0 }"
                ></video>
                <video
                    ref="talk3"
                    class="talkclass"
                    src="@/assets/v2/video/talk2.mp4"
                    :style="{ opacity: talkStatus == 1 && type == 3 ? 1 : 0 }"
                ></video>
                <div class="dlgclass">{{ talklist[type - 1] }}~</div>
                <div v-if="type == 1" class="form">
                    <div class="t_r required">您的称呼</div>
                    <div>
                        <a-input v-model="form.name" placeholder="请输入您的称呼" class="affixIptClass" />
                    </div>
                    <div class="t_r required">联系方式</div>
                    <div><a-input v-model="form.phone" placeholder="请输入您的手机号码" /></div>
                    <div class="t_r">企业名称</div>
                    <div><a-input v-model="form.company" placeholder="请输入企业名称" /></div>
                    <div class="t_r">咨询方案</div>
                    <div class="checkboxS">
                        <!-- @change="onChange" -->
                        <a-checkbox-group v-model="form.checkedList" :options="plainOptions" />
                    </div>
                </div>
                <div v-if="type == 2" class="form form_tell">
                    <div class="infotop">
                        <div>联系人：吴经理</div>
                        <div>电话：18825046050</div>
                        <div>座机：020-37639225</div>
                        <div>邮箱：sywu3@iflytek.com</div>
                    </div>
                    <img src="@/assets/wecode.png" class="tellme" />
                </div>
                <div v-if="type == 3" class="form form_submit">
                    <img src="@/assets/wecode.png" class="submiticon" />
                    <div>信息已提交~</div>
                </div>
            </div>
            <div class="btns">
                <span v-if="[1, 3].includes(type)" @click="onAffixTell">{{ rightBtnsList[type - 1] }}</span>
                <a-tooltip v-if="type == 1 && errorInfo" placement="top">
                    <template slot="title">
                        <span>{{ errorInfo }}</span>
                    </template>
                    <span class="submitclass submitclass-fobid">提交</span>
                </a-tooltip>
                <span v-if="type == 1 && !errorInfo" class="submitclass" @click="onAffixSubmit">提交</span>
                <span v-if="type == 2" @click="onBack">{{ rightBtnsList[type - 1] }}</span>
                <span v-if="[2, 3].includes(type)" @click="onAffixReset">收起对话框</span>
            </div>
        </div>
    </div>
</template>

<script>
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { TALK_LIST } from '@/utils/v1.2data.js';
@Component({
    components: {},
})
export default class Home extends Vue {
    @Prop({ default: true }) isAnimate;
    isOPen = false;
    talkStatus = 0; //0:未开始说话；1：正在说话；2：说话结束
    name = '';
    type = 1;
    talklist = [];
    rightBtnsList = ['直接联系我们', '返回填写', '直接联系我们'];
    plainOptions = ['语音服务', '在线接待', '语义检测', '坐席助理', '虚拟人'];
    form = {
        name: '',
        phone: '',
        company: '',
        checkedList: [],
    };
    errorInfo = '请输入您的称呼和您的手机号码';
    visible = false;
    currentTalkVideo = null;
    @Watch('form', { deep: true })
    onFormChange(val) {
        let arr = [];
        if (!val.name) {
            arr.push('您的称呼');
        }
        if (!val.phone) {
            arr.push('您的手机号码');
        }
        if (arr.length == 0) {
            this.errorInfo = '';
        }
        if (arr.length == 1) {
            this.errorInfo = '请输入' + arr[0];
        }
        if (arr.length == 2) {
            this.errorInfo = '请输入' + arr[0] + '和' + arr[1];
        }
    }
    created() {
        this.talklist = TALK_LIST;
    }
    mounted() {}
    openAffix() {
        if (this.isOPen) return;
        this.isOPen = true;
        this.talkAddEventListener();
    }
    talkAddEventListener() {
        let talk = this.$refs['talk' + this.type];
        this.currentTalkVideo = talk;
        talk.currentTime = 0;
        talk.play();
        let self = this;
        talk.addEventListener('waiting', () => {
            //加载
            console.log('加载中');
        });
        talk.addEventListener('play', () => {
            //播放开始执行的函数
            this.talkStatus = 1;
            console.log('开始播放');
        });
        talk.addEventListener('playing', () => {
            //播放中
            this.talkStatus = 1;
            console.log('播放中');
        });
        talk.addEventListener(
            'ended',
            () => {
                this.talkStatus = 2;
                console.log('播放结束', self.talkStatus, self.type);
            },
            false,
        );
    }
    onAffixTell() {
        this.type = 2;
        this.currentTalkVideo.pause();
        this.talkAddEventListener();
    }
    onBack() {
        this.type = 1;
        this.currentTalkVideo.pause();
        this.talkAddEventListener();
    }
    onAffixSubmit() {
        this.type = 3;
        this.currentTalkVideo.pause();
        this.talkAddEventListener();
        console.log(this.form);
        let { name, phone, company, checkedList } = this.form;
        let str = `/contact?name=${name}&phone=${phone}&company=${company}&plan=${
            checkedList && checkedList.length ? checkedList.join(',') : ''
        }`;
        let origin = window.location.origin;
        fetch(origin + str, {
            headers: {
                'X-APP-VERSION': process.env.VUE_APP_VERSION || 'v1.0.0',
            },
        });
    }
    onAffixReset() {
        this.currentTalkVideo.pause();
        this.isOPen = false;
        this.type = 1;
    }
}
</script>

<style lang="less" scoped>
.submitclass {
    display: inline-block;
}
.form_tell .infotop {
    min-width: 178px;
    color: #46464c;
    font-size: 14px;
    font-style: normal;
    line-height: 26px;
    div {
        word-wrap: break-word;
    }
    & + img.tellme {
        width: 96px;
        height: 96px;
        object-fit: cover;
        position: static;
        margin-top: 20px;
    }
}
.form {
    position: relative;
    z-index: 6;
    width: 100%;
    height: 260px;
    padding: 12px 20px;
    border-radius: 30px;
    background: #ffffff99;
    backdrop-filter: blur(25px);
    margin-top: 12px;
    display: grid;
    grid-template-columns: 62px 1fr;
    justify-content: center;
    align-items: center;
    input {
        width: 168px;
        padding: 0;
        margin-bottom: 8px;
        margin-left: 16px;
        border-color: transparent;
        border-radius: 0;
        background-color: transparent;
        border-bottom: 1px solid #000;
        &:focus {
            box-shadow: none;
        }
        &::-webkit-input-placeholder {
            color: rgba(57, 57, 58, 0.5);
            font-size: 14px;
            line-height: 32px;
        }
    }
    .t_r {
        margin-bottom: 8px;
        color: #46464c;
        font-size: 14px;
        line-height: 32px;
        text-wrap: nowrap;
        &:before {
            content: '*';
            margin-right: 1px;
            color: transparent;
        }
        &.required:before {
            color: #ff6b00;
        }
    }
    &_submit {
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        div {
            width: 180px;
            text-align: center;
        }
        .submiticon {
            width: 96px;
            height: 96px;
            object-fit: cover;
            position: static;
            margin-top: 20px;
        }
    }
    &_tell {
        height: 270px;
        display: flex;
        flex-direction: column;
    }
}
.checkboxS {
    margin-top: 8px;
    font-size: 13px;
    .ant-checkbox-group {
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr 1fr;
        margin-left: 12px;
    }

    /deep/.ant-checkbox-wrapper {
        margin-bottom: 8px;
        width: 100%;
        span {
            font-size: 13px;
        }
        .ant-checkbox-inner {
            border: 1px solid #000;
            border-radius: 4px;
            background-color: transparent;
        }
        .ant-checkbox-checked {
            .ant-checkbox-inner {
                background-color: #0066ff;
                border-color: #0066ff;
            }
        }
    }
}
.dlgclass {
    position: relative;
    z-index: 6;
    width: 275px;
    padding: 8px 12px;
    border-radius: 1px 20px 20px 20px;
    background: linear-gradient(149deg, #0e8cffcc 18.99%, #001d3900 102.64%);
    backdrop-filter: blur(10px);
    color: #fff;
    font-size: 14px;
}
.phoneClass {
    width: 342px;
    height: 662px;
    padding: 12px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 12px 20px 50px 0 #0000004f;
    position: absolute;
    left: -360px;
    bottom: 0;
    transition: all 0.3s;
    &-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        span:nth-child(2) {
            color: #797979;
            font-size: 14px;
            line-height: 26px;
            cursor: pointer;
        }
        span:nth-child(1) {
            color: #000000;
            font-size: 16px;
            line-height: 26px;
        }
    }
    .videobox {
        width: 100%;
        height: 556px;
        padding: 12px;
        padding-top: 190px;
        position: relative;
        overflow: hidden;
        video {
            position: absolute;
            top: 0;
            object-fit: contain;
            left: 0;
            width: 100%;
            z-index: 1;
            transition: all 0.3s;
            &.talkclass {
                z-index: 2;
            }
        }
    }
    .btns {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        padding: 0 8px;
        span {
            width: 160px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            border-radius: 4px;
            font-size: 14px;
            cursor: pointer;
            &:nth-child(1) {
                color: #606266;
                border: 1px solid #d4d7de;
                width: 124px;
            }
            &:nth-child(2) {
                color: #fff;
                background: linear-gradient(126deg, #0085ff 6.18%, #4f6bfe38 203.42%);
            }
        }
    }
}
.contactAffix {
    width: 68px;
    height: 68px;
    position: fixed;
    right: 40px;
    bottom: 60px;
    z-index: 999;
    color: #000;
    .aniclass {
        width: 100%;
        height: 100%;
        transition: all 0.2s;
        cursor: pointer;
        background-image: url('~@/assets/v2/tell1.svg');
        &:hover {
            transform: rotate(10deg) scale(1.1);
            background-image: url('~@/assets/v2/tell2.svg');
        }
    }
}
</style>
