
import { Vue, Component } from 'vue-property-decorator';
@Component
export default class FootBox extends Vue {
    conPhotos = [
        { src: require('@/assets/homeImg/1.png') },
        { src: require('@/assets/homeImg/2.png') },
        { src: require('@/assets/homeImg/3.png') },
        { src: require('@/assets/homeImg/4.png') },
        { src: require('@/assets/homeImg/5.png') },
        { src: require('@/assets/homeImg/6(1).png') },
        { src: require('@/assets/homeImg/6.png') },
        { src: require('@/assets/homeImg/7.png') },
        { src: require('@/assets/homeImg/8.png') },
        { src: require('@/assets/homeImg/9.png') },
        { src: require('@/assets/homeImg/10.png') },
        { src: require('@/assets/homeImg/11.png') },
        { src: require('@/assets/homeImg/12.png') },
        { src: require('@/assets/homeImg/13.png') },
        { src: require('@/assets/homeImg/14.png') },
        { src: require('@/assets/homeImg/15.png') },
        { src: require('@/assets/homeImg/16.png') },
        { src: require('@/assets/homeImg/17.png') },
        { src: require('@/assets/homeImg/18.png') },
        { src: require('@/assets/homeImg/19.png') },
        { src: require('@/assets/homeImg/20.png') },
        { src: require('@/assets/homeImg/21.png') },
        { src: require('@/assets/homeImg/22(1).png') },
        { src: require('@/assets/homeImg/22.png') },
        { src: require('@/assets/homeImg/23.png') },
        { src: require('@/assets/homeImg/24.png') },
        { src: require('@/assets/homeImg/25.png') },
        { src: require('@/assets/homeImg/26.png') },
        { src: require('@/assets/homeImg/27.png') },
        { src: require('@/assets/homeImg/28.png') },
        { src: require('@/assets/homeImg/29.png') },
        { src: require('@/assets/homeImg/30.png') },
        { src: require('@/assets/homeImg/31.png') },
        { src: require('@/assets/homeImg/32.png') },
        { src: require('@/assets/homeImg/33.png') },
        { src: require('@/assets/homeImg/34.png') },
        { src: require('@/assets/homeImg/35.png') },
        { src: require('@/assets/homeImg/36.png') },
        { src: require('@/assets/homeImg/37.png') },
        { src: require('@/assets/homeImg/39.png') },
        { src: require('@/assets/homeImg/40.png') },
    ];
}
