import axios from 'axios';
export const baseURL = process.env.VUE_APP_HTTP;
export type Result = {
    code: number;
    data: any;
    msg: string;
};

export const instance = axios.create({
    withCredentials: false,
    baseURL,
    timeout: 1000 * 60,
    headers: {
        'X-APP-VERSION': process.env.VUE_APP_VERSION || 'v1.0.0',
    },
});

export const axiosGet = (url: string, payload?) => {
    return new Promise((resolve, reject) => {
        instance
            .get(url, {
                params: payload,
            })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
};

export const axiosPost = (url, payload) => {
    return new Promise((resolve, reject) => {
        instance
            .post(url, payload)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
};
