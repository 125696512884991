import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './styles/index.css'; // 全局自定义的css样式
import './styles/variables.less';
// import "ant-design-vue/dist/antd.css";
// import { Slider } from "ant-design-vue";
import { Slider, Tabs, Dropdown, Icon, Menu, Button, Table, Input, Checkbox, Tooltip } from 'ant-design-vue';

// import "@/ant-design";
/* import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css"; */
Vue.config.productionTip = false;
// Vue.use(Antd);
// Vue.component(Slider.name, Slider);
// Vue.component(Slider.name, Slider);
Vue.use(Tooltip);
Vue.use(Slider);
Vue.use(Tabs);
Vue.use(Dropdown);
Vue.use(Icon);
Vue.use(Menu);
Vue.use(Button);
Vue.use(Table);
Vue.use(Input);
Vue.use(Checkbox);

new Vue({
    render: (h) => h(App),
    router,
}).$mount('#app');
