<template>
    <div class="AI-container">
        <!-- 第一部分 -->
        <div class="main-f">
            <h1>知识抽取</h1>
            <p>
                基于强大的预训练生成语言模型，自动从各种形式文档或纯文本中<br />挖掘知识点及问答对，实现非结构化->结构化处理，为智能问答，<br />企业信息化等场景提供文档数据自动转化结构化数据及问答对的能力
            </p>
            <img src="@/assets/AI-Img/知识抽取.jpg" alt="" />
        </div>
        <!-- 第二部分 -->
        <!-- <div class="main-s">
            <h1>快速体验</h1>
            <p>提供A.I.赋能，满足不同场景可交互</p>
            <div class="main-s-container">
                <div class="main-s-l">
                    <p>示例文本：</p>
                    <img src="@/assets/AI-Img/22.png" alt="" />
                </div>
                <div class="main-s-r">
                    <a-tabs default-active-key="1" class="main-C">
                        <a-tab-pane key="1" tab="识别知识">
                            <div class="result-C" style="height: 288px">
                                <div class="thead">
                                    <h6>知识名称</h6>
                                    <h6>知识类别</h6>
                                    <h6>原文位置</h6>
                                </div>
                                <ul class="tbody" style="height: 220px">
                                    <li>
                                        <p>郎平</p>
                                        <p>人物</p>
                                        <p>国女排兵败东瀛之后,有评论员认为,郎平的七仙女打</p>
                                    </li>
                                    <li>
                                        <p>郎平</p>
                                        <p>人物</p>
                                        <p>国女排兵败东瀛之后,有评论员认为,郎平的七仙女打</p>
                                    </li>
                                    <li>
                                        <p>郎平</p>
                                        <p>人物</p>
                                        <p>国女排兵败东瀛之后,有评论员认为,郎平的七仙女打</p>
                                    </li>
                                </ul>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="生成问答对" force-render>
                            <div class="result-C" style="height: 288px">
                                <div class="thead">
                                    <h6>知识名称</h6>
                                    <h6>知识类别</h6>
                                    <h6>原文位置</h6>
                                </div>
                                <ul class="tbody" style="height: 220px">
                                    <li>
                                        <p>郎平</p>
                                        <p>人物</p>
                                        <p>国女排兵败东瀛之后,有评论员认为,郎平的七仙女打</p>
                                    </li>
                                    <li>
                                        <p>郎平</p>
                                        <p>人物</p>
                                        <p>国女排兵败东瀛之后,有评论员认为,郎平的七仙女打</p>
                                    </li>
                                    <li>
                                        <p>郎平</p>
                                        <p>人物</p>
                                        <p>国女排兵败东瀛之后,有评论员认为,郎平的七仙女打</p>
                                    </li>
                                </ul>
                            </div>
                        </a-tab-pane>
                    </a-tabs>
                </div>
            </div>
        </div> -->
        <!-- 第三部分 -->
        <div class="main-t">
            <h1>技术特性</h1>
            <ul>
                <li>
                    <img src="@/assets/solutionImg/r1.png" alt="" />
                    <h4>算法通用性</h4>
                    <p>模型通过大量的通用中文数据训练，可实现在部分场景下，即使无预标注，也可以准确抽取出知识。</p>
                </li>
                <li>
                    <img src="@/assets/solutionImg/8.png" alt="" />
                    <h4>覆盖通用文档</h4>
                    <p>支持通用文档传入，算法具备从文档解析到知识抽取结构化能力。</p>
                </li>
                <li>
                    <img src="@/assets/AI-Img/16.png" alt="" />
                    <h4>训练数据自反哺</h4>
                    <p>从原始文档抽取出的知识将自动回流至算法模型，增量模型效果。</p>
                </li>
            </ul>
        </div>
        <!-- 第四部分 -->
        <div class="main-four">
            <h1>适用场景</h1>
            <ul>
                <li>
                    <img src="@/assets/AI-Img/13.png" alt="" />
                    <h4>企业智能知识库</h4>
                    <div>
                        <p>通过知识抽取将制度文档转化成为结构化的问答对，更方便给员工查阅</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/AI-Img/12.png" alt="" />
                    <h4>知识挖掘</h4>
                    <div>
                        <p>对批量非结构化文本进行知识抽取，提取出新知识点，完善知识库，提高原始数据利用率</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/AI-Img/17.png" alt="" />
                    <h4>文档解读</h4>
                    <div>
                        <p>对批量非结构化文本进行问答抽取，生成对应问答对，降低原始文本的阅读门槛</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/AI-Img/7.png" alt="" />
                    <h4>知识图谱搭建</h4>
                    <div>
                        <p>对批量文本进行三元组知识抽取，高效搭建完善知识图谱</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/AI-Img/9.png" alt="" />
                    <h4>智能问答</h4>
                    <div>
                        <p>将文档抽取生成问答对加入到FAQ知识库中，极大减少人工维护FAQ的工作量</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="main-five">
            <h1>知名企业的信任选择</h1>
            <div class="banner-container">
                <div class="main">
                    <div class="main_l"></div>
                    <Banner></Banner>
                </div>
            </div>
        </div>
        <FootBox></FootBox>
    </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import Banner from '@/components/common/banner.vue';
import FootBox from '@/components/common/footBox.vue';
@Component({
    components: {
        Banner,
        FootBox,
    },
})
export default class AiAbility extends Vue {
    isPlayFile = false;
    desc = '什么是日食';
    txtLen = 5;

    descInput() {
        this.txtLen = this.desc.length;
    }
}
</script>

<style lang="less" scoped>
.AI-container {
    width: 100%;
    color: #fff;
    .main-f {
        position: relative;
        width: 100%;
        height: 544px;
        min-width: 1440px;
        overflow: hidden;

        h1 {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -600px;
            margin-top: -35px;
            width: 350px;
            height: 70px;
            color: #fff;
            font-size: 54px;
            font-weight: 600;
        }
        p {
            position: absolute;
            top: 60%;
            left: 50%;
            margin-left: -600px;
            line-height: 30px;
            font-size: 16px;
        }
        img {
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            height: 544px;
            min-width: 1440px;
            margin-top: -8px;
            transform: translate(-50%, -50%);
            margin-left: -5px;
        }
    }
    //第二部分
    .main-s {
        height: 520px;
        min-width: 1440px;
        overflow: hidden;
        text-align: center;
        h1 {
            margin-top: 100px;
            font-size: 32px;
            font-weight: 600;
            color: #2a2e32;
        }
        p {
            margin-top: 25px;
            font-size: 16px;
            color: #626262;
        }
        .main-s-container {
            width: 1280px;
            margin: 0 auto;
            .main-s-l {
                float: left;
                width: 500px;
                height: 705px;
                p {
                    text-align: left;
                    font-size: 14px;
                    color: #666;
                }
                img {
                    width: 478.3px;
                    height: 300px;
                }
            }
            .main-s-r {
                width: 975px;
                height: 500px;
                margin-left: 500px;
                .main-C {
                    font-size: 16px;
                    /deep/.ant-tabs-nav {
                        left: -300px;
                        font-size: 16px;
                        color: #2a2e32;
                    }
                    /deep/.ant-tabs-bar {
                        border-bottom: 0px;
                    }
                    p {
                        text-align: left;
                        font-size: 14px;
                        color: #666;
                    }
                    .result-C {
                        position: relative;
                        left: 75px;
                        width: 840px;
                        height: 350px;
                        background: #f5f8fd;
                        border: 1px solid #d9e1e9;
                        .thead {
                            width: 100%;
                            height: 50px;
                            background: #e8edf2;
                            h6:nth-of-type(1) {
                                position: absolute;
                                top: 15px;
                                left: 30px;
                                font-size: 16px;
                                font-weight: 600;
                                color: #2a2e32;
                            }
                            h6:nth-of-type(2) {
                                position: absolute;
                                top: 15px;
                                left: 180px;
                                font-size: 16px;
                                font-weight: 600;
                                color: #2a2e32;
                            }
                            h6:nth-of-type(3) {
                                position: absolute;
                                top: 15px;
                                left: 330px;
                                font-size: 16px;
                                font-weight: 600;
                                color: #2a2e32;
                            }
                        }
                        .tbody {
                            display: flex;
                            flex-direction: column;
                            margin-left: 40px;
                            width: 100%;
                            height: 300px;
                            li {
                                height: 40px;
                                p:nth-of-type(1) {
                                    float: left;
                                    margin-left: -10px;
                                    margin-top: 10px;
                                    color: #2a2e32;
                                }
                                p:nth-of-type(2) {
                                    margin-left: 140px;
                                    margin-top: 10px;
                                    color: #2a2e32;
                                }
                                p:nth-of-type(3) {
                                    margin-left: 290px;
                                    margin-top: -35px;
                                    color: #2a2e32;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //第三部分
    .main-t {
        min-width: 1440px;
        margin-top: 100px;
        margin-bottom: 100px;
        text-align: center;
        h1 {
            font-size: 32px;
            color: #2a2e32;
            font-weight: 600;
        }
        ul {
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            margin-top: 50px;
            width: 1000px;
            height: 250px;

            li {
                float: left;
                width: 236px;
                height: 100%;
                h4 {
                    margin-top: 32px;
                    font-size: 24px;
                    font-weight: 600;
                    color: #2a2e32;
                }
                p {
                    margin-top: 16px;
                    font-size: 14px;
                    color: #666;
                    text-align: left;
                }
                img {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }
    //第四部分
    .main-four {
        width: 100%;
        min-width: 1440px;
        height: 450px;
        text-align: center;
        color: #2a2e32;
        background: #f7faff;
        h1 {
            padding-top: 50px;
            font-size: 32px;
            font-weight: 600;
            color: #2a2e32;
        }

        ul {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 1240px;
            margin: 0 auto;
            margin-top: 50px;
            li {
                width: 240px;
                height: 250px;
                text-align: center;
                img {
                    margin-top: 25px;
                }
                h4 {
                    margin-top: 12px;
                    font-size: 20px;
                    font-weight: 600;
                }
                div {
                    margin: 0 auto;
                    margin-top: 25px;
                    width: 172px;
                    text-align: left;
                    p {
                        font-size: 14px;
                        color: #666;
                    }
                }
            }
        }
    }
    //第五部分
    .main-five {
        width: 100%;
        min-width: 1440px;
        height: 500px;
        margin-top: 100px;
        h1 {
            width: 288px;
            height: 45px;
            margin: 0 auto;
            font-size: 32px;
            font-weight: 600;
            color: #2a2e32;
            margin-bottom: 50px;
        }
        .banner-container {
            position: relative;
            width: 100%;
            min-width: 1440px;
            .main_l {
                position: absolute;
                height: 369px;
                width: 50%;
                min-width: 720px;
                background: url(../../assets/banner/8.jpg) no-repeat;
                background-position: center;
                img {
                    height: 369px;
                }
            }
        }
    }
}
</style>
