<template>
    <div class="AI-container">
        <!-- 第一部分 -->
        <div class="main-f">
            <h1>智能写作</h1>
            <p>
                基于强大的预训练生成语言模型，通过输入结构化数据， <br />
                快速输出段落文稿。
            </p>
            <img src="@/assets/AI-Img/智能写作.jpg" alt="" />
        </div>
        <!-- 第二部分 -->
        <div class="main-s">
            <h1>快速体验</h1>
            <p>提供A.I.赋能，满足不同场景可交互</p>
            <div class="main-s-container">
                <div class="main-s-l">
                    <p>示例文本：</p>
                    <ul>
                        <li>
                            <span>分类：</span>
                            <div>彩妆/口红</div>
                        </li>
                        <li>
                            <span>色调：</span>
                            <div>红棕色</div>
                        </li>
                        <li>
                            <span>质地：</span>
                            <div>哑光丝滑</div>
                        </li>
                    </ul>
                </div>
                <div class="main-s-r">
                    <div>秋冬新款口红推荐，红棕尽显温柔气质，哑光丝滑不显唇纹，让你成为气场女王。</div>
                    <!-- <textarea>秋冬新款口红推荐，红棕尽显温柔气质，哑光丝滑不显唇纹，让你成为气场女王。</textarea> -->
                </div>
            </div>
        </div>
        <!-- 第三部分 -->
        <div class="main-t">
            <h1>技术特性</h1>
            <ul>
                <li>
                    <img src="@/assets/AI-Img/18.png" alt="" />
                    <h4>技术先进</h4>
                    <p>基于强大的预训练生成语言模型的先进算法，在多个公开生成数据集上处于领先水平。</p>
                </li>
                <li>
                    <img src="@/assets/AI-Img/6.png" alt="" />
                    <h4>海量数据训练验证</h4>
                    <p>基于海量的真实场景中文数据预训练得到，写作结果流畅通顺，更贴合应用场景。</p>
                </li>
                <li>
                    <img src="@/assets/AI-Img/19.png" alt="" />
                    <h4>算法易用自优化</h4>
                    <p>
                        在部分场景下，只需要标注少量文本即可达到创作需求，标注结果将自动回流至算法模型，增量模型效果。
                    </p>
                </li>
            </ul>
        </div>
        <!-- 第四部分 -->
        <div class="main-four">
            <h1>适用场景</h1>
            <ul>
                <li>
                    <img src="@/assets/AI-Img/13.png" alt="" />
                    <h4>广告文案创作</h4>
                    <div>
                        <p>基于商品属性及卖点，智能创建广告文案</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/AI-Img/20.png" alt="" />
                    <h4>简报生成</h4>
                    <div>
                        <p>输入天气，报站等相关结构化数据，生成一段流畅的播报文本</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/AI-Img/21.png" alt="" />
                    <h4>直播内容创作</h4>
                    <div>
                        <p>直播介绍播报内容智能创作，生成流畅充满激情的介绍语</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="main-five">
            <h1>知名企业的信任选择</h1>
            <div class="banner-container">
                <div class="main">
                    <div class="main_l"></div>
                    <Banner></Banner>
                </div>
            </div>
        </div>
        <FootBox></FootBox>
    </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import Banner from '@/components/common/banner.vue';
import FootBox from '@/components/common/footBox.vue';
@Component({
    components: {
        Banner,
        FootBox,
    },
})
export default class AiAbility extends Vue {
    isPlayFile = false;
    desc = '什么是日食';
    txtLen = 5;

    descInput() {
        this.txtLen = this.desc.length;
    }
}
</script>

<style lang="less" scoped>
.AI-container {
    width: 100%;
    color: #fff;
    .main-f {
        position: relative;
        width: 100%;
        height: 544px;
        min-width: 1440px;
        overflow: hidden;

        h1 {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -600px;
            margin-top: -35px;
            width: 350px;
            height: 70px;
            color: #fff;
            font-size: 54px;
            font-weight: 600;
        }
        p {
            position: absolute;
            top: 60%;
            left: 50%;
            margin-left: -600px;
            line-height: 30px;
            font-size: 16px;
        }
        img {
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            height: 544px;
            min-width: 1440px;
            margin-top: -8px;
            transform: translate(-50%, -50%);
            margin-left: -5px;
        }
    }
    //第二部分
    .main-s {
        height: 585px;
        min-width: 1440px;
        overflow: hidden;
        text-align: center;
        h1 {
            margin-top: 100px;
            font-size: 32px;
            font-weight: 600;
            color: #2a2e32;
        }
        p {
            margin-top: 25px;
            font-size: 16px;
            color: #626262;
        }
        .main-s-container {
            width: 1280px;
            margin: 0 auto;
            //第五页
            .main-s-l {
                float: left;
                width: 582px;
                height: 350px;
                p {
                    text-align: left;
                    font-size: 14px;
                    color: rgba(42, 46, 50, 0.8);
                }
                ul {
                    width: 582px;
                    height: 312px;
                    padding: 35px;
                    border: 1px solid #d9e1e9;

                    li {
                        position: relative;
                        margin-bottom: 16px;
                        width: 500px;
                        height: 42px;
                        span {
                            position: absolute;
                            top: 10px;
                            left: 0px;
                            font-size: 14px;
                            color: #2a2e32;
                        }
                        div {
                            position: absolute;
                            top: 0px;
                            right: 0px;
                            width: 434px;
                            height: 42px;
                            padding-top: 12px;
                            color: #2a2e32;
                            background: #f2f7fd;
                            border-radius: 2px;
                        }
                    }
                }
            }
            .main-s-r {
                float: right;
                position: relative;
                top: 60px;
                width: 600px;
                p {
                    text-align: left;
                    font-size: 14px;
                    color: rgba(42, 46, 50, 0.8);
                }

                div,
                textarea {
                    width: 600px;
                    height: 311px;
                    padding-top: 19.5px;
                    padding-left: 27.2px;
                    text-align: left;
                    resize: none;
                    font-size: 14px;
                    color: #2a2e32;
                    line-height: 28px;
                    outline: none;
                    background: #f5f8fd;
                    border: 1px solid #d9e1e9;
                }
            }
        }
    }
    //第三部分
    .main-t {
        min-width: 1440px;
        margin-top: 100px;
        margin-bottom: 100px;
        text-align: center;
        h1 {
            font-size: 32px;
            color: #2a2e32;
            font-weight: 600;
        }
        ul {
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            margin-top: 50px;
            width: 1000px;
            height: 250px;

            li {
                float: left;
                width: 236px;
                height: 100%;
                h4 {
                    margin-top: 32px;
                    font-size: 24px;
                    font-weight: 600;
                    color: #2a2e32;
                }
                p {
                    margin-top: 16px;
                    font-size: 14px;
                    color: #666;
                    text-align: left;
                }
                img {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }
    //第四部分
    .main-four {
        min-width: 1440px;
        width: 100%;
        min-width: 1440px;
        height: 450px;
        text-align: center;
        color: #2a2e32;
        background: #f7faff;
        h1 {
            padding-top: 50px;
            font-size: 32px;
            font-weight: 600;
            color: #2a2e32;
        }

        ul {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 1000px;
            margin: 0 auto;
            margin-top: 50px;
            li {
                width: 240px;
                height: 250px;
                text-align: center;
                img {
                    margin-top: 25px;
                }
                h4 {
                    margin-top: 12px;
                    font-size: 20px;
                    font-weight: 600;
                }
                div {
                    margin: 0 auto;
                    margin-top: 25px;
                    width: 172px;
                    text-align: left;
                    p {
                        font-size: 14px;
                        color: #666;
                    }
                }
            }
        }
    }
    //第五部分
    .main-five {
        width: 100%;
        min-width: 1440px;
        height: 500px;
        margin-top: 100px;
        h1 {
            width: 288px;
            height: 45px;
            margin: 0 auto;
            font-size: 32px;
            font-weight: 600;
            color: #2a2e32;
            margin-bottom: 50px;
        }
        .banner-container {
            position: relative;
            width: 100%;
            min-width: 1440px;
            .main_l {
                position: absolute;
                height: 369px;
                width: 50%;
                min-width: 720px;
                background: url(../../assets/banner/6.jpg) no-repeat;
                background-position: center;
                img {
                    height: 369px;
                }
            }
        }
    }
}
</style>
