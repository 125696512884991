<template>
    <div class="AI-container">
        <!-- 第一部分 -->
        <div class="main-f">
            <h1>自然语言处理</h1>
            <p>
                基于词法分析，句法分析，语义依存分析能力，分析句子
                <br />各个语言单位之间的语义关联，直接获取深层的语义信息，<br />更加准确理解文字的含义。
            </p>
            <img src="@/assets/AI-Img/自然语言处理.jpg" alt="" />
        </div>
        <!-- 第二部分 -->
        <div class="main-s">
            <h1>快速体验</h1>
            <p>提供A.I.赋能，满足不同场景可交互</p>
            <div class="main-s-container">
                <!--  <a-tabs default-active-key="1" class="title">
                    <a-tab-pane key="1" tab="语音合成"> -->
                <div class="main-s-l">
                    <p>示例文本：</p>
                    <div class="example-s">
                        <!-- <textarea maxlength="100" @input="descInput" v-model="desc" /><span class="txt-len"
                                    >({{ txtLen }}/100)</span
                                > -->
                        <!-- <a-button class="btn"> 试一下 </a-button> -->
                        <div>
                            <p>什么是日食</p>
                            <span class="txt-len">({{ txtLen }}/100)</span>
                        </div>
                    </div>
                </div>

                <div class="main-s-r">
                    <p>回答：</p>
                    <div>
                        日食，又叫做日蚀，是月球运动到太阳和地球中间，如果三者正好处在一条直线时，月球就会挡住太阳射向地球的光，月球身后的黑影正好落到地球上，这时发生日食现象。
                    </div>
                    <!--  <textarea>
日食，又叫做日蚀，是月球运动到太阳和地球中间，如果三者正好处在一条直线时，月球就会挡住太阳射向地球的光，月球身后的黑影正好落到地球上，这时发生日食现象</textarea
                    > -->
                </div>
                <!-- </a-tab-pane>
                    <a-tab-pane key="2" tab="语音转写"> -->
                <!-- <div class="main-s-l">
                            <p>示例文本：</p>
                            <div class="example-s"> -->
                <!--  <textarea v-model="desc" disabled readonly maxlength="100" @input="descInput" /><span
                                    class="txt-len"
                                    >({{ txtLen }}/100)</span
                                > -->
                <!-- <div>
                                    <p>什么是日食</p>
                                    <span class="txt-len">({{ txtLen }}/100)</span>
                                </div> -->
                <!-- <a-button class="btn"> 试一下 </a-button> -->
                <!--           </div>
                        </div>

                        <div class="main-s-r">
                            <p>回答：</p>
                            <textarea>
日食，又叫做日蚀，是月球运动到太阳和地球中间，如果三者正好处在一条直线时，月球就会挡住太阳射向地球的光，月球身后的黑影正好落到地球上，这时发生日食现象</textarea
                            >
                        </div>
                    </a-tab-pane>
                </a-tabs> -->
            </div>
        </div>
        <!-- 第三部分 -->
        <div class="main-t">
            <h1>技术特性</h1>
            <ul>
                <li>
                    <img src="@/assets/solutionImg/8.png" alt="" />
                    <h4>技术先进</h4>
                    <p>行业先进中文自然语言处理系统，多次国内外技术评测中获得优异成绩。</p>
                </li>
                <li>
                    <img src="@/assets/AI-Img/6.png" alt="" />
                    <h4>服务稳定</h4>
                    <p>服务稳定，吞吐量大，为广大用户提供电信级稳定性和支持全国范围网络接入服务。</p>
                </li>
                <li>
                    <img src="@/assets/solutionImg/位图(2).png" alt="" />
                    <h4>效果自训练学习</h4>
                    <p>基于不同的使用场景，可进行语义理解训练学习，优化效果。</p>
                </li>
            </ul>
        </div>
        <!-- 第四部分 -->
        <div class="main-four">
            <h1>适用场景</h1>
            <ul>
                <li>
                    <img src="@/assets/AI-Img/7.png" alt="" />
                    <h4>语义理解</h4>
                    <div>
                        <p>通过分析一段话的句法结构，提取其主干信息，帮助更好的理解用户意图</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/AI-Img/8.png" alt="" />
                    <h4>搜索引擎</h4>
                    <div>
                        <p>精准的分词结果，会提高搜索匹配的准确率</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/AI-Img/3.png" alt="" />
                    <h4>智能客服</h4>
                    <div>
                        <p>
                            以分词和词性标注为基础，分析语音命令中的分词，可以更加准确理解文字的含义，更好的进行客服服务
                        </p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/AI-Img/9.png" alt="" />
                    <h4>问答系统</h4>
                    <div>
                        <p>对提出的问题进行句法分析，从而理解意图，找到匹配的答案</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/AI-Img/10.png" alt="" />
                    <h4>智能出题</h4>
                    <div>
                        <p>通过对句法结构了解，可基于结构化知识点，反向构建不同语境下的提问句式</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/AI-Img/11.png" alt="" />
                    <h4>舆情分析</h4>
                    <div>
                        <p>通过对网络内容进行分词和词性标注分析，能更好的挖掘社会舆论风向</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="main-five">
            <exampleCard :is-animate="true" :type="1"></exampleCard>
        </div>
        <FootBox></FootBox>
    </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import Banner from '@/components/common/banner.vue';
import FootBox from '@/components/common/footBox.vue';
import exampleCard from '@/components/common/swiperCard.vue';

@Component({
    components: {
        Banner,
        FootBox,
        exampleCard,
    },
})
export default class AiAbility extends Vue {
    isPlayFile = false;
    desc = '什么是日食';
    txtLen = 5;

    descInput() {
        this.txtLen = this.desc.length;
    }
}
</script>

<style lang="less" scoped>
.AI-container {
    width: 100%;
    color: #fff;
    .main-f {
        position: relative;
        width: 100%;
        height: 544px;
        min-width: 1440px;
        overflow: hidden;

        h1 {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -600px;
            margin-top: -35px;
            width: 350px;
            height: 70px;
            color: #fff;
            font-size: 54px;
            font-weight: 600;
        }
        p {
            position: absolute;
            top: 60%;
            left: 50%;
            margin-left: -600px;
            line-height: 30px;
            font-size: 16px;
        }
        img {
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            height: 544px;
            min-width: 1440px;
            margin-top: -8px;
            transform: translate(-50%, -50%);
            margin-left: -5px;
        }
    }
    //第二部分
    .main-s {
        min-width: 1440px;
        height: 585px;
        overflow: hidden;
        text-align: center;
        h1 {
            margin-top: 100px;
            font-size: 32px;
            font-weight: 600;
            color: #2a2e32;
        }
        p {
            margin-top: 25px;
            font-size: 16px;
            color: #626262;
        }
        .main-s-container {
            width: 1280px;
            margin: 0 auto;
            /*  .title {
                font-size: 16px;
                /deep/.ant-tabs-nav {
                    left: -520px;
                }
                /deep/.ant-tabs-bar {
                    border-bottom: 0px;
                } */
            p {
                text-align: left;
                font-size: 14px;
                color: #666;
            }
            // 第二页
            .main-s-l {
                float: left;
                p {
                    text-align: left;
                    font-size: 14px;
                    color: #666;
                }
                .example-s {
                    position: relative;
                    width: 600px;
                    height: 311px;
                    box-sizing: border-box;
                    text-align: left;
                    border: 1px solid #d9e1e9;

                    textarea,
                    div {
                        height: 177px;
                        padding-left: 27.2px;
                        resize: none;
                        font-size: 14px;
                        color: #2a2e32;
                        outline: none;
                        border: none;
                        p {
                            font-size: 14px;
                            color: #2a2e32;
                        }
                    }
                    p {
                        margin-top: 24px;
                        font-size: 14px;
                        color: #2a2e32;
                    }
                    .txt-len {
                        position: absolute;
                        bottom: 24px;
                        right: 25px;
                        font-size: 14px;
                        color: #666;
                    }
                    .btn {
                        position: absolute;
                        bottom: -1px;
                        left: -1px;
                        width: 600px;
                        height: 48px;
                        color: #fff;
                        font-size: 16px;
                        border-radius: 0px;
                        background: #1890ff;
                    }
                }
            }
            .main-s-r {
                float: left;
                position: relative;
                left: 50px;
                width: 600px;
                p {
                    text-align: left;
                    font-size: 14px;
                    color: rgba(42, 46, 50, 0.8);
                }

                div,
                textarea {
                    width: 600px;
                    height: 311px;
                    padding-top: 19.5px;
                    padding-left: 27.2px;
                    resize: none;
                    font-size: 14px;
                    text-align: left;
                    color: #2a2e32;
                    line-height: 28px;
                    outline: none;
                    background: #f5f8fd;
                    border: 1px solid #d9e1e9;
                }
            }
            // }
        }
    }
    //第三部分
    .main-t {
        min-width: 1440px;
        margin-top: 100px;
        margin-bottom: 100px;
        text-align: center;
        h1 {
            font-size: 32px;
            color: #2a2e32;
            font-weight: 600;
        }
        ul {
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            margin-top: 50px;
            width: 1000px;
            height: 250px;

            li {
                float: left;
                width: 236px;
                height: 100%;
                h4 {
                    margin-top: 32px;
                    font-size: 24px;
                    font-weight: 600;
                    color: #2a2e32;
                }
                p {
                    margin-top: 16px;
                    font-size: 14px;
                    color: #666;
                    text-align: left;
                }
                img {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }
    //第四部分
    .main-four {
        width: 100%;
        min-width: 1440px;
        height: 650px;
        text-align: center;
        color: #2a2e32;
        background: #f7faff;
        h1 {
            padding-top: 50px;
            font-size: 32px;
            font-weight: 600;
            color: #2a2e32;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            width: 1140px;
            margin: 0 auto;
            margin-top: 50px;
            li {
                width: 380px;
                height: 250px;
                text-align: center;
                img {
                    margin-top: 25px;
                }
                h4 {
                    margin-top: 12px;
                    font-size: 20px;
                    font-weight: 600;
                }
                div {
                    margin: 0 auto;
                    margin-top: 25px;
                    width: 172px;
                    text-align: left;
                    p {
                        font-size: 14px;
                        color: #666;
                    }
                }
            }
        }
    }
    //第五部分
    .main-five {
        width: 100%;
        min-width: 1440px;
        margin-top: 100px;
        h1 {
            width: 288px;
            height: 45px;
            margin: 0 auto;
            font-size: 32px;
            font-weight: 600;
            color: #2a2e32;
            margin-bottom: 50px;
        }
        .banner-container {
            position: relative;
            width: 100%;
            min-width: 1440px;
            .main_l {
                position: absolute;
                height: 369px;
                width: 50%;
                min-width: 720px;
                background: url(../../assets/banner/7.jpg) no-repeat;
                background-position: center;
                img {
                    height: 369px;
                }
            }
        }
    }
}
</style>
