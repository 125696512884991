
import { Vue, Component } from 'vue-property-decorator';
import Banner from '@/components/common/banner.vue';
import FootBox from '@/components/common/footBox.vue';
import exampleCard from '@/components/common/swiperCard.vue';
import { VIRTUAL_TOP } from '@/utils/v1.2data';
@Component({
    components: {
        Banner,
        FootBox,
        exampleCard,
    },
})
export default class Solution extends Vue {
    info = VIRTUAL_TOP;
}
