let THREE_BOTTOM_LIST = [
    '通话分析小结',
    '文案创作助手',
    '百科知识问答',
    '文章润色',
    '更多',
    '基于通话内容，总结提取要点',
    '请填写商业领域及受众群体，助手为您…',
    '输入您的提问，我将为您解答',
    '输入对文章的要求，为您提供润色后…',
    '一个指令，开启更多应用场景…',
];
let QUE_LIST = ['为我总结一下文档内容吧~', '帮我写一篇羽绒服的宣传推广文吧', '可以介绍下清明上河图吗'];
let ANS_LIST = [
    '大熊猫是一种以竹子为主要食物的大型哺乳动物，属于熊科、大熊猫属，是中国的特有种。它仅有二个亚种，分别是四川亚种和秦岭亚种，主要栖息地在中国四川、陕西和甘肃的山区。雄性个体稍大于雌性。 大熊猫曾在地球上生存了至少800万年，被誉为“活化石”和“中国国宝”，是世界生物多样性保护的旗舰物种。大熊猫的体型肥硕似熊、丰腴富态，头圆尾短，头躯长1.2-1.8米，尾长10-12厘米。体重80-120千克，最重可达180千克，体色为黑白两色，脸颊圆，有很大的“黑眼圈”，标志性的内八字的行走方式，也有解剖刀般锋利的爪子。 大熊猫善于爬树，也爱嬉戏。爬树的行为一般是临近求婚期，或逃避危险，或彼此相遇时弱者借以回避强者的一种方式。大熊猫每天除去进食的一半时间，剩下的时间多数时候都是在睡梦中度过。在野外，大熊猫在每两次进食的中间睡2-4个小时。大熊猫99%的食物都是竹子，可供大熊猫食用的竹类植物共有12属、60多种。野外大熊猫的寿命为18-20岁，圈养状态下可以超过30岁。',
    '【冬日暖心推荐】羽绒服穿搭指南，让你美丽又保暖！<br/>亲爱的朋友们，随着冬季的到来，寒风凛冽，我来给大家分享一下关于羽绒服的一些穿搭技巧和选购建议，希望能帮助大家在寒冷的冬季里既保暖又时尚。<br/>首先，要怎么样选购羽绒服呢？我们要关注的几个重要指标包括填充物、含绒量、面料和工艺。填充物主要有鹅绒和鸭绒两种，其中鹅绒的保暖性能更好，但价格也相对较高。含绒量是指羽绒中绒的比例，一般来说，含绒量越高，保暖性能越好。面料要选择防风防水的，这样既能抵挡寒风，又能防止雪水渗入。工艺方面，建议大家选择采用立体裁剪的羽绒服，这样的羽绒服更能贴合身体曲线，穿着更加舒适。<br/>这里给大家提供个羽绒服搭配思路：<br/>羽绒服+紧身裤，紧身裤能够突显腿部线条，与羽绒服搭配，既能保暖又能显瘦。或者羽绒服+牛仔裤，百搭不出错；最后加上围巾和靴子，优化整体比例~<br/>最后提醒下大家，要注意羽绒服的清洗和保养，避免因为不正确的清洗方法导致羽绒服变形或者保暖性能下降哦~',
    '《清明上河图》是中国十大传世名画之一，由北宋画家张择端创作，是一幅具有重要历史价值和艺术价值的风俗画长卷。它描绘的是清明节日北宋都城汴梁（今开封）和以虹桥为中心的汴河两岸各阶层人物活动情景。<br/><br/>这幅作品以其独特的散点透视构图法，生动记录了中国十二世纪北宋都城东京（又称汴京，今河南开封）的城市面貌和当时社会各阶层人民的生活状况，是北宋时期都城东京当年繁荣的见证，也是北宋城市经济情况的写照。在五米多长的画卷里，共绘制了数量庞大的各色人物，牛、骡、驴等牲畜，车、轿、大小船只，房屋、桥梁、城楼等各有特色，体现了宋代建筑的特征。<br/><br/>此外，《清明上河图》还因其对于社会生活的深度描绘和艺术技巧的精湛运用，被誉为“中国古代社会生活的一部百科全书”。这在中国乃至世界绘画史上都是独一无二的。因此，《清明上河图》不仅具有高度的历史价值，反映了北宋时期的社会风貌和人民生活，同时也具有极高的艺术价值，展示了张择端等古代艺术家们的高超绘画技艺。',
];

//联系我们
let TALK_LIST = [
    '您好，我是爱因智能虚拟助理Alexia，请您留下您的联系方式，我们将会有专人联系您',
    '欢迎您直接来电或联系我们的企业微信哦',
    '感谢您的来访，我们会尽快联系您',
];

//headtop
let HEADTOP = {
    company: '爱因智能',
    t1: '多场景人工智能',
    t2: '应用解决方案',
    t3: '利用语音、视觉和自然语言理解等核心人工智能技术，为各行业提供一站式软硬件智能化解决方案，助力企业实现更高效的业务运营。',
    t4: '观看我们的宣传视频，深入了解我们多样化的应用方案和创新成果，让您对我们的能力有更直观的认识。',
    t5: '多场景解决方案',
    t6: '提供A.I.赋能的个性化服务，满足不同场景可交互，识别客户意图',
};

let BRANCH_SUM = [
    {
        id: 1,
        text2: '屈臣氏是亚洲最大的健康与美容零售集团。根据屈臣氏提出外呼触达难、营销推广效果弱、人力成本高的难点，爱因为其私有化部署外呼系统，针对不同的业务场景以及不同的会员群体，定制新的功能触点和设计多套专业话术，实现给予屈臣氏会员特殊节日关怀、调用品牌代言明星声音进行互动、精准投送优惠信息给不同群体等营销方式。短时间高并发的智能外呼一天能为屈臣氏节省7200小时的人力成本。',
        text3: '屈臣氏',
        src: require('@/assets/v2/logo/qcs.png'),
        projectname: '智能语音综合服务',
        type: '连锁零售行业',
    },
    {
        id: 2,
        text2: '杭州老板电器股份有限公司专业生产吸油烟机、集成油烟机、蒸箱、灶具、消毒柜、电烤箱、微波炉、洗碗机、净水器、燃气热水器等家用厨房电器产品。爱因为其部署智能热线客服并对接其客户CRM系统，能够自动识别比对顾客来电号码名下订单信息，在与用户交互中提取有效字段（地址、日期、产品类型），自动导入工单系统，最终实现送货安装工单的无人化创建填写并完成多品类的家电产品送货安装事项。',
        text3: '老板电器',
        src: require('@/assets/v2/logo/lb.png'),
        projectname: '智能语音综合服务',
        type: '家用电器行业',
    },
    {
        id: 3,
        text2: '美赞臣是一家全球领先的生产婴幼儿营养品的公司，专注于婴幼儿配方奶粉、维生素和矿物质等营养品。面对其咨询需求庞多、解决效率过低、服务体验较差的问题，爱因开发对接美赞臣CRM系统的智能语音机器人，智能客服能够根据客户高频咨询，提供物流查询催单、门店查询、会员积分查询、产品真伪辨别溯源等自助服务。爱因还为其部署了智能外呼系统，能够对各类客户群体进行拉新、大促、复购提醒、转段留存等主动推广触达。',
        text3: '美赞臣',
        src: require('@/assets/v2/logo/mzc.png'),
        projectname: '智能语音综合服务',
        type: '母婴行业',
    },
    {
        id: 4,
        text2: ' 广州图书馆是广州的文化窗口，2018年被中华人民共和国文化和旅游部评定为国家一级图书馆。广图为了使群众业务办理和问题咨询操作更简单，联合爱因打造对接图书馆公众号、官网的在线智能客服，通过自动化对话的方式，发送音频、链接等信息帮助读者快速完成图书检索、图书续借、证件挂失等业务问题的咨询，简化读者咨询流程。此外，广图在线智能客服还支持中粤两种语言服务，支持读者粤语语音输入询问，便利读者的语言习惯。',
        text3: '广州图书馆',
        src: require('@/assets/v2/logo/gztsg.png'),
        projectname: '全渠道富文本智能交互',
        type: '文化行业',
    },
    {
        id: 5,
        text2: ' 长隆集团是一家集主题公园、度假酒店、文化演艺、特色餐饮、商务会展、科普教育为一体的大型文旅集团。长隆在其小程序、APP、公众号、官网接入爱因的智能在线客服，系统整合多个板块和业务线，构建长隆客服知识体系，内含文字、图片、链接等多种形式的各大园区和酒店的知识。消费者可通过与机器人交互，实现六大乐园、八大酒店自助购退票及订单查询。',
        text3: '长隆',
        src: require('@/assets/v2/logo/cl.png'),
        projectname: '全渠道富文本智能交互',
        type: '主题公园行业',
    },
    {
        id: 7,
        text2: ' Hennessy轩尼诗干邑是世界上三大干邑品牌之一。2019年10月，Interbrand发布的全球品牌百强榜排名95。爱因在其小程序上搭建语音交互机器人与用户进行互动，以假期娱乐场景、送礼场景、音乐场景、调酒场景、干邑知识场景等为交互内容触点，在不同的场景下AI进行人群识别、需求洞察，并进一步进行轩尼诗的个性化产品推荐，通过链接到达购物商城实现交易闭环，从而促进轩尼诗产品变现。',
        text3: '轩尼诗',
        src: require('@/assets/v2/logo/hen.png'),
        projectname: '全渠道富文本智能交互',
        type: '酒水行业',
    },
    {
        id: 8,
        text2: ' 广东南越王博物院中国首批国家一级博物馆之一，也是中国南方最大的博物馆之一。出于对资产数字化的需求以及改善交互效果的需要，广东南越王博物院携手爱因智能在其官方公众号中嵌入虚拟形象小羽供游客进行文字/语音交互，运用语义分析、语音转写、语音合成、WebSocket等多领域技术让游客在与小羽沟通中更深入地了解南越文化，通过带社交功能的数字空间给游客带来更强的沉浸式体验。',
        text3: '南越王博物院',
        src: require('@/assets/v2/logo/nyw.png'),
        projectname: '虚拟人智能交互接待',
        type: '文旅行业',
    },
    {
        id: 9,
        text2: '爱康国宾是爱康集团旗下广为人知的品牌，面向团体客户和家庭、个人提供高品质的健康体检、疾病检测和私人医生等健康管理服务。为了让客户在获取体检知识、疾病咨询、报告解读时获得交互式的体验，爱康比较国内其他语音合成技术巨头，最终与爱因达成合作。爱因为其进行3D建模设计生成虚拟人形象爱康AI健康管家ikkie，并特别定制了ikkie的形象和声音，ikkie还可以通过多轮对话设计出融合情感理解的动作和表情，拉近与用户的情感距离并建立情感的联系。',
        text3: '爱康国宾',
        src: require('@/assets/v2/logo/akgb.png'),
        projectname: '虚拟人智能交互接待',
        type: '健康产业',
    },
    {
        id: 10,
        text2: '广州友谊集团有限公司是岭南商旅集团旗下定位高端经营的商贸板块企业，涵盖百货、奥特莱斯、精品超市、线上商城等多种经营业态。爱因在其实体门店消费场景的基础上构建智能客服YOYO，可应用不限于微信公众号、手机常规浏览器、app的h5页面等多种场景，根据顾客需求进行自动化的引导，完成智能导购、商品介绍、促销信息发布等各类活动咨询和问题回答。',
        text3: '广州友谊',
        src: require('@/assets/v2/logo/y.png'),
        projectname: '虚拟人智能交互接待',
        type: '百货商超行业',
    },
    {
        id: 11,
        text2: '越秀地产是全国第一批成立的综合性房企之一，公司位列中国地产TOP20、广州市场TOP1。爱因智能提供的语义识别技术，能够实现定期对服务咨询通话进行质量检测分析，筛选出重点投诉内容信息以便管理人员重点跟进反馈。通过质检模型还能够对客服人员的服务态度、专业规范等服务质量作出评分，从而发现客服人员在服务中的问题，来确保服务过程的可追溯性，从而使其进一步优化服务，提升企业对外形象。',
        text3: '越秀地产',
        src: require('@/assets/v2/logo/yxdc.png'),
        projectname: '语义检测服务',
        type: '地产行业',
    },
    {
        id: 12,
        text2: '阳光保险旗下拥有财产保险、人寿保险、信用保证保险、资产管理等多家专业子公司。是中国500强企业和中国100强服务业企业之一。 面对其数量庞大、专业度高、场景多样的质检需求，爱因智能提供本地化部署智能质检平台，用AI赋能质检工作，平台能够自动处理呼叫中心全量对话录音和视频，质检覆盖率达到100%。通过智能质检还可以让其及时规范人工坐席的服务态度、专业程度、流程进度，以满足保监会规范要求。',
        text3: '阳光保险',
        src: require('@/assets/v2/logo/ygbx.png'),
        projectname: '语义检测服务',
        type: '保险行业',
    },
    {
        id: 13,
        text2: '马士基中国是全球性综合集装箱物流企业，港口遍布全球，2023年马士基集团位列《财富》世界500强第151名。马士基通过爱因设计的智能质检系统对客服对话录音进行全量质检，运用语音转写和支持中英专业词句的语义分析，将客服的响应性、服务态度、问题解决能力等多个维度转化成客观判断标准进行评判，以用于客服个人的绩效评定和能力评估。还对所有录音进行语义聚类，分析出每个周期客户反映次数较多的热点话题，以此作为马士基每个月度客服团队培训的内容之一，不断更新改进服务。',
        text3: '马士基',
        src: require('@/assets/v2/logo/msj.png'),
        projectname: '语义检测服务',
        type: '运输行业',
    },
    {
        id: 14,
        text2: '深信服科技股份有限公司是一家专注于企业级网络安全、云计算、IT基础设施与物联网的产品和服务供应商，业务覆盖全球50多个国家和地区。针对其产品知识庞多复杂且相关知识对应难的痛点，爱因智能为其提供私有化部署知识中台系统、中央知识库开发多渠道对接，坐席助手系统，为深信服人工坐席在电话售后服务过程中提供知识抽取、知识推荐和流程指引。帮助其在复杂的对话场景中快速提取定位有用信息，减少了40%的通话时长和人工服务差错率，投诉率降低约30%。',
        text3: '深信服',
        src: require('@/assets/v2/logo/sxf.png'),
        projectname: '语音坐席助理',
        type: '互联网行业',
    },
    {
        id: 15,
        text2: '宝洁公司是全球最大的日用消费品公司之一，拥有多个知名品牌，产品线涵盖多个领域，在全球拥有超过160个国家的业务。宝洁与爱因智能聚势合作超7年时间，宝洁也是快消领域首个与爱因进行大模型应用探索的品牌。爱因为其部署机器人语音坐席助理和大模型助手，为呼叫中心坐席人员提供智能辅助。坐席助理将通话内容转为文本，实时匹配知识点内容，大模型助手针对通话过程中提及的产品信息进行精准提取并实时输出人工坐席与顾客通话的内容小结，帮助人工坐席快速掌握顾客的诉求，提升咨询服务水平。',
        text3: '宝洁',
        src: require('@/assets/v2/logo/bj.png'),
        projectname: '语音坐席助理',
        type: '快消品行业',
    },
    {
        id: 16,
        text2: '12351是中华全国总工会职工维权热线，面对广东省范围内问题咨询、服务帮扶、信访投诉、法律援助、意见建议等职工维权服务需求，全国总工会选择与爱因智能合作，让咨询处理更高效。爱因提供的坐席助手可以自动根据对话内容，调用案例知识库里的相似案例、相关办事流程信息，大大提升坐席接待的效率。此外，当识别出对话内容触发高危、敏感信息标签，坐席助手会实时提醒坐席注意引导方式并提示坐席主管重点监察。',
        text3: '12351热线',
        src: require('@/assets/v2/logo/12351.png'),
        projectname: '语音坐席助理',
        type: '政府部门',
    },
];

let SCONTIANER = [
    {
        src: require('@/assets/v2/scenario/1.png'),
        img: require('@/assets/v2/s1-v2.png'),
        title: '智能语音综合服务',
        text: '智能语音接待及辅助应用',
        link: '/solution',
        descMain: '基于先进的语音合成和识别技术，及自然语言处理技术，实现智能化接待及辅助人工应用。',
        desc: [
            '支持呼入和呼出场景，支持隐私号对接及外显企业手机号',
            '强大的转接、咨询功能，并能生成客户信息工单',
            '通过意向识别和短信触发等功能，提高客户服务的效率',
            '支持打断、重复操作，并可以自定义呼叫规则',
            '我们提供SaaS化和私有化的部署方式，满足不同企业的需求',
            '支持普通话、英语、粤语等多种语言',
        ],
    },
    {
        src: require('@/assets/v2/scenario/2.png'),
        img: require('@/assets/v2/s2-v2.png'),
        title: '全渠道富文本智能交互',
        text: '智能富文本多轮交互',
        link: '/solution/richText',
        descMain: '支持全渠道接入，多样式的智能化文本交互，提供全天候在线支撑',
        desc: [
            '机器人7*24小时在线独立接待，为客户提供不间断的服务',
            '智能多轮对话，支持表情、图片、视频、链接等丰富的文本内容交互，提升用户体验',
            '实现微信公众号、小程序、APP、PC等多渠道接入，方便不同用户随时互动',
            '借助大数据，掌握客户画像，实现千人千面的个性化沟通',
        ],
    },
    {
        src: require('@/assets/v2/scenario/3.png'),
        img: require('@/assets/v2/s3-v2.png'),
        title: '虚拟人智能交互接待',
        text: '实体导览及咨询问答',
        link: '/solution/virtual',
        descMain: '支持轻量化定制形象，为用户带来全新智能化交互体验',
        desc: [
            '支持H5、小程序、APP等多种渠道接入，方便不同用户随时互动',
            '通过多模态生成式AI运营，全面升级交互体验',
            '可定制声音和形象，实现与真人形态、动作、声音的一比一还原，适配不同业务场景',
            '支持一张照片进行轻量化训练，能够快速定制专属形象',
        ],
    },
    {
        src: require('@/assets/v2/scenario/4.png'),
        img: require('@/assets/v2/s4-v2.png'),
        title: '语义检测服务',
        text: '多角色多场景语义检测',
        link: '/solution/detection',
        descMain: '支持将海量录音文件转化为可视化数据，实行全量质检',
        desc: [
            '具备全量质检评分功能，能够全面评估服务质量',
            '语义引擎深度分析，内容精细定位，深入了解客户需求和偏好',
            '挖掘潜在合作机会，为企业带来更多商机探索',
            '提供可视化数据报表输出，直观呈现分析结果',
        ],
    },
    {
        src: require('@/assets/v2/scenario/5.png'),
        img: require('@/assets/v2/s5-v2.png'),
        title: '语音坐席助理',
        text: '多类型虚拟形象及场景化应用',
        link: '/solution/voiceAssistant',
        descMain: '实时转录语音对话内容，智能推荐坐席知识，自动生成通话总结',
        desc: [
            '实时语音转写，对话内容一目了然',
            '精准识别客户意图，智能推荐坐席知识，提升服务质量',
            '借助大语言模型的助力，智能总结对话要点，提供高效的通话总结',
        ],
    },
];
let INDUSTYR = [
    {
        title: '快消零售',
        content: '通过AI对话服务延长售前售后顾问链条，全方位了解消费者的产品需求，构建360度用户画像',
        src: require('@/assets/homeImg/retail.jpg'),
        link: '/customerCase',
    },
    {
        title: '地产物业',
        content: '提升企业服务能力，全面铺设线上自助服务渠道，对人财物进行AI数字化统筹',
        src: require('@/assets/homeImg/build.jpg'),
        link: '/customerCase/property',
    },
    {
        title: '母婴乳制',
        content: '帮助企业搭建AI营销体系，快速实现获客裂变，加强品牌与消费者连结',
        src: require('@/assets/homeImg/mon.jpg'),
        link: '/customerCase/maternal',
    },
    {
        title: '汽车行业',
        content: '辅助企业进行海量线索的快速清洗，深挖线索客户需求，唤醒车企线索中沉睡的价值',
        src: require('@/assets/homeImg/car.jpg'),
        link: '/customerCase/carIndustry',
    },
];
let VIRTUAL_TOP = {
    t1: '虚拟人',
    t2: '智能交互接待',
    t3: '支持轻量化定制形象，为用户带来全新智能化交互体验',
    t4: '一张照片，',
    t5: '即可进行轻量化训练，',
    t6: '生成专属形象',
    t7: '虚拟人制作流程',
    t8: '支持形象类型',
    t9: '真人照片',
    t10: '写实3D形象',
    t11: '2.5D半二次元形象',
};
let MODEL_INFO = {
    t1: 'AI大语言模型',
    t2: '接入大模型能力，让您的应用快速拥有跨领域的知识和强大的自然语言理能力',
    t3: '拥有跨领域知识和语言理解能力，能够基于自然对话方式理解与执行任务',
    t4: '只需要一个指令，',
    t5: '将懂你所言，',
    t6: '答你所问，',
    t7: '创你所需',
    t8: '大熊猫百科',
    t9: '文本理解',
    t10: '文案创作',
    t11: '知识问答',
    t12: '多场景智能应用',
    t13: 'v3.5重磅升级',
};
let VOICE_INFO = {
    t1: '语音坐席助理',
    t2: '实时转录语音对话内容，智能推荐坐席知识，自动生成通话总结',
    t3: '双声道实时转写',
    t4: '双声道实时转写，客户来电咨询内容一目了然。',
    t5: '服务话术推荐',
    t6: '意图识别检测，智能推荐关联话术，助力客户服务。',
    t7: '智能通话总结',
    t8: '借助AI大语言模型，智能总结对话要点，提供高效的通话总结。',
    t9: '标准化接入',
    t10: '无需改造现有客服接待系统，标准化接入使用。',
};
export {
    VOICE_INFO,
    MODEL_INFO,
    VIRTUAL_TOP,
    INDUSTYR,
    THREE_BOTTOM_LIST,
    QUE_LIST,
    ANS_LIST,
    TALK_LIST,
    HEADTOP,
    BRANCH_SUM,
    SCONTIANER,
};
