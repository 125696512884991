<template>
    <div class="cutom-swiper" :class="{ staticSwiper: !isAnimate, staticSwiperhome: type == 'home' }">
        <!-- 卡片 -->
        <span class="main_s-t" :style="{ marginBottom: isAnimate ? '10px' : '60px' }">
            {{ isAnimate ? '知名企业的信任选择' : '案例概述' }}
        </span>
        <!-- <div style="width: 100%; overflow-x: hidden"> -->
        <div class="swiperBox" :style="{ height: swiperBoxHeight + 'px', overflowX: isAnimate ? 'hidd' : 'inherit' }">
            <img v-if="isAnimate" src="@/assets/v2/whitemask.png" class="mask" />
            <div
                id="inner"
                class="inner"
                :class="{ play: isAnimate }"
                :style="{
                    width: isAnimate ? '200vw' + 'px' : '100%',
                    justifyContent: isAnimate ? 'flex-start' : 'center',
                    alignItems: isAnimate ? 'center' : 'flex-start',
                }"
            >
                <div
                    v-for="(item, i) in isAnimate ? [...bannertext, ...bannertext] : bannertext2"
                    :key="i"
                    class="itembox"
                    :style="{ marginRight: !isAnimate && i == bannertext2.length - 1 ? '0' : '30px' }"
                >
                    <div class="itemclass">
                        <div class="branchimgbox">
                            <img :src="item.src" class="cor-img" />
                        </div>
                        <div class="item_top" :style="{ width: isAnimate ? '470px' : '300px' }">
                            <span>{{ item.text3 }}</span>
                            <span style="padding: 8px 30px">{{ item.type }}</span>
                        </div>
                        <div class="item-txt" :style="{ width: isAnimate ? '470px' : '300px' }">
                            {{ item.text2 }}
                        </div>
                        <div v-if="isAnimate" class="item_bo">{{ item.projectname }}</div>
                    </div>
                </div>
            </div>
            <div class="clear"></div>
        </div>
        <!-- </div> -->
    </div>
</template>

<script>
import { BRANCH_SUM } from '@/utils/v1.2data';

import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({
    components: {},
})
export default class Home extends Vue {
    @Prop({ default: true }) isAnimate;
    @Prop({ default: '' }) type;
    itemnum = 1;
    itemWidth = 550;
    swiperBoxHeight = 620;
    swiperBox = null;
    lenthArr = [];
    bannertext2 = [];
    bannertext = BRANCH_SUM;
    routerObj = {
        '/solution': 0,
        '/solution/richText': 3,
        '/solution/virtual': 6,
        '/solution/detection': 9,
        '/solution/voiceAssistant': 12,
        '/aiAbility/natural': [15, 3, 1],
        '/aiAbility/ORCImage': [15, 3, 1],
    };

    mounted() {
        console.log('4', this.$route);
        let start = this.routerObj[this.$route.path];
        if (!this.isAnimate) {
            if (typeof start === 'number') {
                this.bannertext2 = this.bannertext.slice(start, start + 3);
            } else {
                this.bannertext2 = this.bannertext.filter((item) => start.includes(item.id));
            }
        }

        this.itemnum = this.bannertext.length;
        this.creatKeyframes((this.itemWidth + 30) * this.itemnum);
        let lenthArr = (this.isAnimate ? this.bannertext : this.bannertext2).map((item, i) => {
            return {
                index: i,
                length: item.text2.length,
            };
        });
        lenthArr.sort(function (a, b) {
            return b.length - a.length;
        });
        this.lenthArr = lenthArr;
        if (lenthArr && lenthArr.length) {
            let itemboxs = document.querySelectorAll('.itembox');
            itemboxs.forEach((item, i) => {
                if (i == lenthArr[0].index) {
                    this.swiperBoxHeight = item.offsetHeight + 60;
                }
            });
        }
    }

    creatKeyframes(number, name = 'cardscroll') {
        // js创建@keyframes，ball从定位在(10,10)的位置运动到(100,100) 的位置
        const runkeyframes = ` 
          @keyframes ${name} {
            0% {
              transform: translateX(0px);
            }
            100% {
              transform: translateX(-${number}px);
            }
          }
          `;
        // 创建style标签
        const style = document.createElement('style');
        // 设置style属性
        style.type = 'text/css';
        // 将 keyframes样式写入style内
        style.innerHTML = runkeyframes;
        // 将style样式存放到head标签
        document.querySelector('head').appendChild(style);
    }
}
</script>

<style lang="less" scoped>
.cutom-swiper {
    width: 100%;
    min-width: 1440px;
}
.staticSwiper {
    .item-txt {
        height: 390px !important;
    }
}
span.main_s-t {
    display: block;
    text-align: center;
    font-size: 32px;
    color: #000;
    font-weight: 600;
    margin: 0 auto;
    margin-top: 130px;
}
.staticSwiperhome {
    span.main_s-t {
        color: #2a2e32;
        font-size: 40px;
        background-clip: text;
        background-image: radial-gradient(circle 300px, rgb(0, 0, 0), rgba(255, 255, 255, 0.6));
        color: transparent;
        width: 374px;
        -webkit-background-clip: text;
    }
}
.clear {
    clear: both;
}
.swiperBox {
    width: 100%;
    background-color: #fff;
    position: relative;
    overflow-x: hidden;
    .mask {
        pointer-events: none;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0%;
        z-index: 9;
    }
}
.inner {
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    &.play {
        animation: cardscroll 260s linear infinite normal;
    }
    &:hover {
        animation-play-state: paused;
    }
}
.itembox {
    margin-right: 30px;
}
.itemclass {
    background: #fff;
    box-sizing: content-box;
    transition: all 0.3s;
    box-shadow: 0px 0px rgba(103, 118, 140, 0.1), 0em 0 60px rgba(103, 118, 140, 0.1);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 40px;
    .branchimgbox {
        width: 211px;
        height: 102px;
    }
    .cor-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .item_top {
        width: 470px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-top: 40px;
        > span:nth-child(1) {
            color: #070707;
            font-size: 24px;
            font-weight: 600;
        }
        > span:nth-child(2) {
            padding: 10px 30px;
            border-radius: 4px;
            color: #75859d;
            font-size: 16px;
            background: linear-gradient(101deg, #ebebef4d 41.72%, #cbe9eb0d 190.13%);
        }
    }
    .item_bo {
        align-self: flex-start;
        border-radius: 2px;
        background: #0066ff2e;
        padding: 6px 12px;
        text-align: center;
        color: #0066ff;
        font-size: 16px;
        margin-top: 20px;
    }
    .item-txt {
        width: 470px;
        word-break: break-all;
        color: #070707;
        font-size: 14px;
        line-height: 30px;
    }
}
.play .itemclass {
    &:hover {
        padding: 60px !important;
        box-shadow: 0px 0px rgba(103, 118, 140, 0.35), 0em 0 20px rgba(103, 118, 140, 0.35);
    }
}
</style>
