<template>
    <div class="AI-container">
        <!-- 第一部分 -->
        <div class="main-f">
            <h1>OCR图像识别</h1>
            <p>
                强大的图像内容提取识别能力，包括通用图片文字识别，票据 <br />
                文字识别，物体识别等通用场景能力
            </p>
            <img src="@/assets/AI-Img/qcr图像识别.jpg" alt="" />
        </div>
        <!-- 第二部分 -->
        <div class="main-s">
            <h1>快速体验</h1>
            <p>提供A.I.赋能，满足不同场景可交互</p>
            <div class="main-s-container">
                <!-- 第三页 -->
                <a-tabs default-active-key="1" class="title">
                    <a-tab-pane key="1" tab="通用图片识别">
                        <div class="main-s-l">
                            <p>示例图片：</p>
                            <div class="example-s">
                                <img class="main-p" src="@/assets/AI-Img/小弹窗(1).jpg" alt="" />
                                <!--  <ul class="rest-p">
                                    <li>
                                        <img src="@/assets/AI-Img/小弹窗 (1).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="@/assets/AI-Img/小弹窗 (1).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="@/assets/AI-Img/小弹窗 (1).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="@/assets/AI-Img/小弹窗 (1).png" alt="" />
                                    </li>
                                </ul> -->
                            </div>
                        </div>
                        <div class="main-s-r">
                            <p class="result">识别结果：</p>
                            <div class="result-C" style="height: 348px">
                                <div class="thead">
                                    <h6>序号</h6>
                                    <h6>内容</h6>
                                </div>
                                <ul class="tbody" style="height: 220px">
                                    <li>
                                        <p>1</p>
                                        <p>服饰与美容</p>
                                    </li>
                                    <li>
                                        <p>2</p>
                                        <p>vogue</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab=" 票据文字识别" force-render>
                        <div class="main-s-l">
                            <p>示例图片：</p>
                            <div class="example-s">
                                <img class="main-p" src="@/assets/AI-Img/小弹窗(2).jpg" alt="" />
                                <!--  <ul class="rest-p">
                                    <li>
                                        <img src="@/assets/AI-Img/小弹窗 (1).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="@/assets/AI-Img/小弹窗 (1).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="@/assets/AI-Img/小弹窗 (1).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="@/assets/AI-Img/小弹窗 (1).png" alt="" />
                                    </li>
                                </ul> -->
                            </div>
                        </div>
                        <div class="main-s-r">
                            <p class="result">识别结果：</p>
                            <div class="result-C">
                                <div class="thead">
                                    <h6>序号</h6>
                                    <h6>内容</h6>
                                </div>
                                <ul class="tbodyP">
                                    <li>
                                        <p>发票种类</p>
                                        <p>专用发票</p>
                                    </li>
                                    <li>
                                        <p>发票名称</p>
                                        <p>广州增值税专用发票</p>
                                    </li>
                                    <li>
                                        <p>发票代码</p>
                                        <p>4400212130</p>
                                    </li>
                                    <li>
                                        <p>发票号码</p>
                                        <p>28841374</p>
                                    </li>
                                    <li>
                                        <p>开票日期</p>
                                        <p>2021年09月22日</p>
                                    </li>
                                    <li>
                                        <p>购方名称</p>
                                        <p>广东宝洁科技创新有限公司</p>
                                    </li>
                                    <li>
                                        <p>购方纳税人识别号</p>
                                        <p>91440101MA5AKGPFX1</p>
                                    </li>
                                    <li>
                                        <p>合计金额</p>
                                        <p>12500.00</p>
                                    </li>
                                    <li>
                                        <p>合计税额</p>
                                        <p>750.0</p>
                                    </li>
                                    <li>
                                        <p>价税合计(大写)</p>
                                        <p>壹万叁仟贰佰伍拾圆整</p>
                                    </li>
                                    <li>
                                        <p>价税合计(小写)</p>
                                        <p>13250.00</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="3" tab="物体识别">
                        <div class="main-s-l">
                            <p>示例图片：</p>
                            <div class="example-s">
                                <img class="main-p" src="@/assets/AI-Img/小弹窗.jpg" alt="" />
                                <!--  <ul class="rest-p">
                                    <li>
                                        <img src="@/assets/AI-Img/小弹窗 (1).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="@/assets/AI-Img/小弹窗 (1).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="@/assets/AI-Img/小弹窗 (1).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="@/assets/AI-Img/小弹窗 (1).png" alt="" />
                                    </li>
                                </ul> -->
                            </div>
                        </div>
                        <div class="main-s-r">
                            <p class="result">识别结果：</p>
                            <div class="result-C" style="height: 348px">
                                <div class="thead">
                                    <h6>序号</h6>
                                    <h6>内容</h6>
                                </div>
                                <ul class="tbodyW" style="height: 220px">
                                    <li>
                                        <p>1</p>
                                        <p>汤勺</p>
                                    </li>
                                    <li>
                                        <p>2</p>
                                        <p>茶壶</p>
                                    </li>
                                    <li>
                                        <p>3</p>
                                        <p>车厘子</p>
                                    </li>
                                    <li>
                                        <p>4</p>
                                        <p>车厘子</p>
                                    </li>
                                    <li>
                                        <p>5</p>
                                        <p>茶杯</p>
                                    </li>
                                </ul>
                            </div>
                        </div></a-tab-pane
                    >
                </a-tabs>
            </div>
        </div>
        <!-- 第三部分 -->
        <div class="main-t">
            <h1>技术特性</h1>
            <ul>
                <li>
                    <img src="@/assets/AI-Img/位图(2).png" alt="" />
                    <h4>超高准确度</h4>
                    <p>智能识别算法，准确识别图像中的文字及生活常见物体。</p>
                </li>
                <li>
                    <img src="@/assets/AI-Img/6.png" alt="" />
                    <h4>适应复杂环境</h4>
                    <p>
                        在模糊、倾斜、光照不均、背景杂乱等情况下均能有优异表现，支持扫描体以及复杂自然场景下的文字识别。
                    </p>
                </li>
                <li>
                    <img src="@/assets/AI-Img/位图(1).png" alt="" />
                    <h4>高效稳定处理</h4>
                    <p>毫秒级响应速度，并在实际场景中不断优化性能表现。</p>
                </li>
            </ul>
        </div>
        <!-- 第四部分 -->
        <div class="main-four">
            <h1>适用场景</h1>
            <ul>
                <li>
                    <img src="@/assets/AI-Img/12.png" alt="" />
                    <h4>内容审核与分析</h4>
                    <div>
                        <p>
                            提取图像中文字后，结合语义理解识别违规内容，可用于广告审核，舆情监管等场景。有效提升内容分类，检索的效率
                        </p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/AI-Img/13.png" alt="" />
                    <h4>纸质文档电子化</h4>
                    <div>
                        <p>识别提取各类纸质文档中的文字信息，并进行结构化处理，提高信息录入，存档，检索的效率</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/AI-Img/14.png" alt="" />
                    <h4>票据验真</h4>
                    <div>
                        <p>智能识别票据关键字段，自动化进行查验，有效降低人力成本</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/AI-Img/7.png" alt="" />
                    <h4>智能内容推荐</h4>
                    <div>
                        <p>识别用户浏览网页中的图片信息，推送相关内容或产品</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/AI-Img/15.png" alt="" />
                    <h4>以图搜物</h4>
                    <div>
                        <p>自动化检测展示图片内物体信息，达到以图搜物的目的</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="main-five">
            <exampleCard :is-animate="true" :type="1"></exampleCard>
        </div>
        <FootBox></FootBox>
    </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import Banner from '@/components/common/banner.vue';
import FootBox from '@/components/common/footBox.vue';
import exampleCard from '@/components/common/swiperCard.vue';

@Component({
    components: {
        Banner,
        FootBox,
        exampleCard,
    },
})
export default class AiAbility extends Vue {
    isPlayFile = false;
    desc = '什么是日食';
    txtLen = 5;

    descInput() {
        this.txtLen = this.desc.length;
    }
}
</script>

<style lang="less" scoped>
.AI-container {
    width: 100%;
    color: #fff;
    .main-f {
        position: relative;
        width: 100%;
        height: 544px;
        min-width: 1440px;
        overflow: hidden;
        h1 {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -600px;
            margin-top: -35px;
            width: 350px;
            height: 70px;
            color: #fff;
            font-size: 54px;
            font-weight: 600;
        }
        p {
            position: absolute;
            top: 60%;
            left: 50%;
            margin-left: -600px;
            line-height: 30px;
            font-size: 16px;
        }
        img {
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            height: 544px;
            min-width: 1440px;
            margin-top: -8px;
            transform: translate(-50%, -50%);
            margin-left: -5px;
        }
    }
    //第二部分
    .main-s {
        height: 700px;
        min-width: 1440px;
        overflow: hidden;
        text-align: center;
        h1 {
            margin-top: 100px;
            font-size: 32px;
            font-weight: 600;
            color: #2a2e32;
        }
        p {
            margin-top: 25px;
            font-size: 16px;
            color: #626262;
        }
        .main-s-container {
            width: 1280px;
            margin: 0 auto;
            //第三页
            .title {
                font-size: 16px;
                /deep/.ant-tabs-nav {
                    left: -445px;
                }
                /deep/.ant-tabs-bar {
                    border-bottom: 0px;
                }
                p {
                    text-align: left;
                    font-size: 14px;
                    color: #666;
                }
                .main-s-l {
                    float: left;
                    width: 700px;
                    p {
                        text-align: left;
                        font-size: 14px;
                        color: rgba(42, 46, 50, 0.8);
                    }
                    .example-s {
                        position: relative;
                        width: 700px;
                        height: 500px;

                        .main-p {
                            position: absolute;
                            top: 0px;
                            left: -40px;
                            width: 685px;
                            height: 348px;
                        }
                        .rest-p {
                            display: flex;
                            justify-content: space-between;
                            position: absolute;
                            bottom: 145px;
                            left: -40px;
                            width: 670px;
                            padding: 0px;
                            li {
                                width: 142.2px;
                                height: 72px;
                                padding: 0px;
                            }
                        }
                    }
                }
                .main-s-r {
                    float: right;
                    width: 500px;
                    height: 450px;
                    .result {
                        font-size: 14px;
                        color: #2a2e32;
                    }
                    .result-C {
                        position: relative;
                        width: 500px;
                        background: #f5f8fd;
                        border: 1px solid #d9e1e9;
                        .thead {
                            width: 100%;
                            height: 40px;
                            background: #e8edf2;
                            h6:nth-of-type(1) {
                                position: absolute;
                                top: 8px;
                                left: 40px;
                                font-size: 16px;
                                font-weight: 600;
                                color: #2a2e32;
                            }
                            h6:nth-of-type(2) {
                                position: absolute;
                                top: 8px;
                                left: 265px;
                                font-size: 16px;
                                font-weight: 600;
                                color: #2a2e32;
                            }
                        }
                        .tbody,
                        .tbodyP,
                        .tbodyW {
                            display: flex;
                            flex-direction: column;
                            margin-left: 40px;
                            width: 100%;
                            height: 290px;
                            li {
                                height: 40px;
                                p:nth-of-type(1) {
                                    float: left;
                                    margin-top: 10px;
                                    color: #2a2e32;
                                }
                                p:nth-of-type(2) {
                                    margin-left: 225px;
                                    margin-top: 10px;
                                    color: #2a2e32;
                                }
                            }
                        }
                        .tbodyP {
                            li {
                                height: 27px;
                                p:nth-of-type(1) {
                                    float: left;
                                    margin-top: 5px;
                                    color: #2a2e32;
                                }
                                p:nth-of-type(2) {
                                    margin-left: 225px;
                                    margin-top: 5px;
                                    color: #2a2e32;
                                }
                            }
                        }
                        .tbodyW {
                            li {
                                height: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
    //第三部分
    .main-t {
        min-width: 1440px;
        margin-top: 100px;
        margin-bottom: 100px;
        text-align: center;
        h1 {
            font-size: 32px;
            color: #2a2e32;
            font-weight: 600;
        }
        ul {
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            margin-top: 50px;
            width: 1000px;
            height: 250px;

            li {
                float: left;
                width: 236px;
                height: 100%;
                h4 {
                    margin-top: 32px;
                    font-size: 24px;
                    font-weight: 600;
                    color: #2a2e32;
                }
                p {
                    margin-top: 16px;
                    font-size: 14px;
                    color: #666;
                    text-align: left;
                }
                img {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }
    //第四部分
    .main-four {
        min-width: 1440px;
        width: 100%;
        height: 450px;
        text-align: center;
        color: #2a2e32;
        background: #f7faff;
        h1 {
            padding-top: 50px;
            font-size: 32px;
            font-weight: 600;
            color: #2a2e32;
        }

        ul {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 1240px;
            margin: 0 auto;
            margin-top: 50px;
            li {
                width: 240px;
                height: 250px;
                text-align: center;
                img {
                    margin-top: 25px;
                }
                h4 {
                    margin-top: 12px;
                    font-size: 20px;
                    font-weight: 600;
                }
                div {
                    margin: 0 auto;
                    margin-top: 25px;
                    width: 172px;
                    text-align: left;
                    p {
                        font-size: 14px;
                        color: #666;
                    }
                }
            }
        }
    }
    //第五部分
    .main-five {
        width: 100%;
        min-width: 1440px;
        margin-top: 100px;
        h1 {
            width: 288px;
            height: 45px;
            margin: 0 auto;
            font-size: 32px;
            font-weight: 600;
            color: #2a2e32;
            margin-bottom: 50px;
        }
        .banner-container {
            position: relative;
            width: 100%;
            min-width: 1440px;
            .main_l {
                position: absolute;
                height: 369px;
                width: 50%;
                min-width: 720px;
                background: url(../../assets/banner/7.jpg) no-repeat;
                background-position: center;
                img {
                    height: 369px;
                }
            }
        }
    }
}
</style>
