
import { Vue, Component } from 'vue-property-decorator';
import Banner from '@/components/common/banner.vue';
import FootBox from '@/components/common/footBox.vue';
import exampleCard from '@/components/common/swiperCard.vue';

import { getDownloadUrl } from '@/service/download';
import { baseURL, instance } from '@/service';
import { Result } from '@/service';

@Component({
    components: {
        Banner,
        FootBox,
        exampleCard,
    },
})
export default class Solution extends Vue {
    clicked = false;
    timeout: any = null;
    visibleModal: boolean = false;

    beforeDestroy() {
        clearTimeout(this.timeout);
    }

    async handleDownload() {
        if (this.clicked) return;

        this.clicked = true;
        this.timeout = setTimeout(() => {
            this.clicked = false;
        }, 3000);

        const result_url = await getDownloadUrl();

        if (!(result_url as Result).code) {
            const downloadUrl = (result_url as Result).data.downloadUrl;
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = 'AIAD Live.exe';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }

    async downloadPdf() {
        console.log('baseURL', baseURL);
        instance
            .get(`${baseURL}/zhibo/systemConfig/download?systemConfigId=1430002288835723245`, {
                responseType: 'blob',
            })
            .then((res) => {
                const a = document.createElement('a');
                a.download = '爱播助手使用指引v1.5.pdf';
                a.href = URL.createObjectURL(res.data);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
    }

    async playVideo() {
        this.visibleModal = true;
        (document.getElementById('vid_video') as any).play();
    }

    closeModal() {
        this.visibleModal = false;
        (document.getElementById('vid_video') as any).pause();
    }
}
