<template>
    <div class="main_r">
        <div ref="arrowlC" class="arrow-l" @click="preview">
            <!--   @mouseenter="stopBanner"
                        @mouseleave="startBanner" -->
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 30"
                class="design-iconfont"
                style="width: 13px; margin-top: 2px"
            >
                <path
                    ref="arrowl"
                    transform="matrix(0 1 -1 0 31.845517 -8.5)"
                    d="M9.7436875 16.9816875L23.1093125 30.0183125 37.2563125 16.9816875"
                    stroke="#fff"
                    stroke-width="2"
                    fill="none"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                />
            </svg>
        </div>
        <div ref="arrowrC" class="arrow-r" @click="next">
            <!-- @mouseenter="stopBanner" @mouseleave="startBanner" -->
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 30"
                class="design-iconfont"
                style="width: 13px; margin-top: 2px"
            >
                <path
                    ref="arrowr"
                    transform="matrix(0 1 -1 0 31.845517 -8.5)"
                    d="M9.7436875 16.9816875L23.1093125 30.0183125 37.2563125 16.9816875"
                    stroke="#FFF"
                    stroke-width="2"
                    fill="none"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                />
            </svg>
        </div>
        <!-- 轮播图 -->
        <div id="bannerlist" ref="banner">
            <ul ref="bannerList" class="bannerlist">
                <li v-for="(item, index) in bannertext" :key="index" ref="bannerItem">
                    <img src="@/assets/solutionImg/b.png" alt="" />
                    <div>
                        <p>{{ item.text1 }}</p>
                        <p>{{ item.text2 }}</p>
                        <p>{{ item.text3 }}</p>
                    </div>
                    <img :src="item.src" alt="" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    prop: {
        leftPicture: {},
    },
    data() {
        return {
            leftPicture: this.leftPicture,
            bannertext: [
                {
                    text1: '2018年6月，宝洁公司引进爱因智能的“一站式”用户互动新模式，“A.I.金牌销售助理”助力宝洁7*24小时不间断解答消费者问题，这也是爱因智能与快销品行业的首次深层合作。',
                    text2: ' 商业生态的变化也影响着广告和营销形态的变化，后续双方想在全新的人工智能AIoT时代，进行更深层次合作，建立自己的MarTech智能营销系统，更好地与自己的用户发生更多连接。',
                    text3: '-宝洁中国',
                    src: require('@/assets/banner/P&g.png'),
                },
                {
                    text1: '2018年6月，全球知名的母婴快销品行业龙头和著名品牌美赞臣与新一代人工智能开放创新平台爱因智能牵手，以美赞臣-爱因智能智慧客服中心为平台首次探索母婴快销品行业的A.I.服务新模式“金牌助理”。',
                    text2: ' “金牌助理”摆脱了市面常见的“播报式”人机对话，真正的实现了多轮问答、业务快速跳转、顾客地址定位、短信升级等多项服务，以超过90%的服务满意度领跑业界。',
                    text3: '-美赞臣中国',
                    src: require('@/assets/banner/美赞成.png'),
                },
                {
                    text1: '2020年6月，全球知名的国际保健美容零售商屈臣氏与爱因智能科技合作，构建全数据智能服务平台。',
                    text2: '屈臣氏在中国内地490多个城市拥有超过4,100家店铺和6,300万名会员，为了能够更好的服务与触动消费者，利用全数据智能服务平台A.I.能够在不同的时间和场景，帮助品牌对用户的触达变得更立体；让品牌价值传递更有效。',
                    text3: '-屈臣氏中国',
                    src: require('@/assets/banner/屈臣氏.png'),
                },
                {
                    text1: '2018年6月，宝洁公司引进爱因智能的“一站式”用户互动新模式，“A.I.金牌销售助理”助力宝洁7*24小时不间断解答消费者问题，这也是爱因智能与快销品行业的首次深层合作。',
                    text2: ' 商业生态的变化也影响着广告和营销形态的变化，后续双方想在全新的人工智能AIoT时代，进行更深层次合作，建立自己的MarTech智能营销系统，更好地与子级的用户发生更多连接。',
                    text3: '-宝洁中国',
                    src: require('@/assets/banner/P&g.png'),
                },
            ],
            timer: 0,
            num: 0,
        };
    },
    methods: {
        preview() {
            let bannerWidth = this.$refs.banner.offsetWidth;
            let ul = this.$refs.bannerList;
            //先快速滚到第一张图片
            if (this.num == 0) {
                this.num = ul.children.length - 1;
                ul.style.left = 0;
                ul.style.left = -this.num * bannerWidth + 'px';
            }
            this.num--;
            this.animate(ul, -this.num * bannerWidth);
        },
        /* stopBanner() {
        clearInterval(this.timer);
        this.timer = 0; //清除定时器
    }
    startBanner() {
        setInterval(() => {
            //手动调用点击事件
            this.next();
        }, 2000);
    } */
        next() {
            let bannerWidth = this.$refs.banner.offsetWidth;
            let ul = this.$refs.bannerList;
            if (this.num == ul.children.length - 1) {
                this.num = 0;
                ul.style.left = 0;
            }
            this.num++;
            this.animate(ul, -this.num * bannerWidth);
        },
        // 自动播放轮播图（轮播图每隔2s滚动）
        animate(obj, target) {
            clearInterval(obj.timer);
            obj.timer = setInterval(function () {
                var step = (target - obj.offsetLeft) / 10;
                step = step > 0 ? Math.ceil(step) : Math.floor(step);
                if (obj.offsetLeft == target) {
                    clearInterval(obj.timer);
                }
                obj.style.left = obj.offsetLeft + step + 'px';
            }, 15);
        },
    },
};
</script>

<style lang="less" scoped>
.main_r {
    position: absolute;
    right: 0px;
    width: 50%;
    height: 369px;
    min-width: 720px;
    background: linear-gradient(134deg, #76a2c6 0%, #1021d1 100%);
    .arrow-l {
        position: absolute;
        z-index: 1;
        bottom: 25px;
        right: 175px;
        width: 47px;
        height: 47px;
        cursor: pointer;
        text-align: center;
        padding-top: 10px;
        border-radius: 10px;
        background: #4563e2;
    }
    .arrow-l:hover {
        background: #458afb;
    }
    .arrow-r {
        position: absolute;
        z-index: 1;
        bottom: 25px;
        right: 105px;
        width: 47px;
        height: 47px;
        cursor: pointer;
        text-align: center;
        padding-top: 10px;
        background: #4563e2;
        transform: scaleX(-1);
        border-radius: 10px;
    }
    .arrow-r:hover {
        background: #458afb;
    }
    // 轮播图ul盒子
    #bannerlist {
        position: relative;
        top: calc((100% - 290px) / 2);
        left: calc((100% - 600px) / 2);
        float: left;
        width: 500px;
        height: 280px;
        /* 把其他的隐藏掉 */
        overflow: hidden;
        .bannerlist {
            position: absolute;
            left: 0;
            /* 长度为4张图 */
            width: 400%;
            font-size: 13px;
            li {
                width: 500px;
                height: 280px;
                float: left;
                img:nth-of-type(1) {
                    float: left;
                    width: 34px;
                    height: 34px;
                }
                img:nth-of-type(2) {
                    position: absolute;
                    bottom: 0px;
                    width: 60px;
                    height: 60px;
                    margin-left: 20px;
                }
                div {
                    float: right;
                    width: 450px;
                    line-height: 2;
                    color: #fff;
                    margin-top: 20px;
                    p:nth-of-type(3) {
                        float: right;
                    }
                }
            }
        }
    }
}
</style>
