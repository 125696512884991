<template>
    <div class="AI-container">
        <div class="main-f">
            <h1>语音AI能力</h1>
            <h4>语音转写:</h4>
            <p>可批量将音频文件转换成文本数据，适用于语音质检、会议访谈等场景。</p>
            <h4>语音合成：</h4>
            <p>将文字信息转化为声音信息，让机器像人一样开口说话。</p>
            <img src="@/assets/AI-Img/语音ai能力.jpg" alt="" />
        </div>
        <div class="main-t">
            <h1>技术特性</h1>
            <ul>
                <li>
                    <img src="@/assets/solutionImg/r1.png" alt="" />
                    <h4>准确率高，高效稳定</h4>
                    <p>行业先进的语音技术，通用语音识别率高达98%。云端语音能力引擎高效稳定，实时响应。</p>
                </li>
                <li>
                    <img src="@/assets/AI-Img/位图(3).png" alt="" />
                    <h4>多语种多音色</h4>
                    <p>
                        支持中文、英文等多种语种声音；支持根据业务需求选择合适的音量、语速等属性；更有多种发音人音色供选择。
                    </p>
                </li>
                <li>
                    <img src="@/assets/solutionImg/位图(2).png" alt="" />
                    <h4>个性热词，效果优化</h4>
                    <p>无需算法开发，简单上传常用词句。优化识别效果，提高个性化准确率。</p>
                </li>
                <li>
                    <img src="@/assets/solutionImg/3.png" alt="" />
                    <h4>智能断句</h4>
                    <p>根据语义语境，智能停顿断句，智能添加标点。</p>
                </li>
            </ul>
        </div>
        <div class="main-four">
            <h1>适用场景</h1>
            <ul>
                <li>
                    <img src="@/assets/AI-Img/b-2.png" alt="" />
                    <h4>语音导航</h4>
                    <div>
                        <p>通过合成语音为用户提供语音导航服务</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/AI-Img/1.png" alt="" />
                    <h4>提示播报</h4>
                    <div>
                        <p>高铁或广场，通过语音合成进行语音播报，广播通知；或在直播场景中进行商品介绍播报</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/AI-Img/2.png" alt="" />
                    <h4>新闻听书</h4>
                    <div>
                        <p>通过手机或音箱收听小说或新闻时，为用户提供不同发音人播读功能</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/AI-Img/3.png" alt="" />
                    <h4>电话销售&客服</h4>
                    <div>
                        <p>合成语音可使机器人模拟真人客服进行接待销售； 可将人工坐席录音转写，进行电话质检</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/AI-Img/4.png" alt="" />
                    <h4>会议&访谈记录</h4>
                    <div>
                        <p>将会议和访谈的音频转换成文字存稿，让后期的信息检索和整理更方便快捷</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/AI-Img/5.png" alt="" />
                    <h4>语音质检</h4>
                    <div>
                        <p>可以从转写出的文字结果中搜索匹配相关词类，对黄暴/涉政内容进行高效鉴别</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="main-five">
            <SwiperCard :is-animate="true"></SwiperCard>
        </div>
        <FootBox></FootBox>
    </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import FootBox from '@/components/common/footBox.vue';
import SwiperCard from '@/components/common/swiperCard.vue';
@Component({
    components: {
        SwiperCard,
        FootBox,
    },
})
export default class AiAbility extends Vue {
    isPlayFile = false;
    desc = '什么是日食';
    txtLen = 5;
    descInput() {
        this.txtLen = this.desc.length;
    }
}
</script>

<style lang="less" scoped>
.AI-container {
    width: 100%;
    color: #fff;
    .main-f {
        position: relative;
        width: 100%;
        height: 544px;
        min-width: 1440px;
        overflow: hidden;

        h1 {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -600px;
            margin-top: -35px;
            width: 350px;
            height: 70px;
            color: #fff;
            font-size: 54px;
            font-weight: 600;
        }
        h4:nth-of-type(1) {
            position: absolute;
            top: 60%;
            left: 50%;
            margin-left: -600px;
            color: #fff;
            font-size: 20px;
        }
        h4:nth-of-type(2) {
            position: absolute;
            top: 75%;
            left: 50%;
            margin-left: -600px;
            color: #fff;
            font-size: 20px;
        }
        p:nth-of-type(1) {
            position: absolute;
            top: 65%;
            left: 50%;
            margin-left: -600px;
            color: #fff;
            font-size: 20px;
        }
        p:nth-of-type(2) {
            position: absolute;
            top: 80%;
            left: 50%;
            margin-left: -600px;
            color: #fff;
            font-size: 20px;
        }
        p {
            font-size: 16px;
        }
        img {
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            height: 544px;
            min-width: 1440px;
            margin-top: -8px;
            transform: translate(-50%, -50%);
            margin-left: -5px;
        }
    }
    //第二部分
    .main-s {
        min-width: 1440px;
        height: 674px;
        overflow: hidden;
        text-align: center;
        h1 {
            margin-top: 100px;
            font-size: 32px;
            font-weight: 600;
            color: #2a2e32;
        }
        p {
            margin-top: 25px;
            font-size: 16px;
            color: #626262;
        }
        .main-s-container {
            width: 1280px;
            margin: 0 auto;
            .title {
                font-size: 16px;
                /deep/.ant-tabs-nav {
                    left: -520px;
                }
                /deep/.ant-tabs-bar {
                    border-bottom: 0px;
                }
                p {
                    text-align: left;
                    font-size: 14px;
                    color: #666;
                }
                //第一页
                .example-f {
                    width: 1280px;
                    box-sizing: border-box;
                    height: 242px;
                    text-align: left;
                    border: 1px solid #d9e1e9;

                    textarea {
                        height: 177px;
                        padding-top: 19.5px;
                        padding-left: 27.2px;
                        resize: none;
                        font-size: 14px;
                        color: #2a2e32;
                        outline: none;
                        border: none;
                    }
                    .choose {
                        position: relative;
                        display: flex;
                        height: 67px;
                        margin-top: -10px;
                        padding-top: 28px;
                        padding-left: 27.2px;
                        font-size: 14px;
                        color: #000;
                        background: #f5f7fa;
                        .choose-color {
                            display: flex;
                            justify-content: space-between;
                            color: #2a2e32;
                            .sound-color {
                                color: #1890ff;
                            }
                        }
                        .choose-speed,
                        .choose-sound {
                            display: flex;
                            width: 250px;
                            .speed {
                                width: 100px;
                                margin-left: 16px;

                                /deep/.ant-slider-mark {
                                    display: none;
                                }
                                /deep/.ant-slider-step {
                                    display: none;
                                }
                            }
                            /deep/.ant-slider {
                                margin: 4px 8px 0px 16px;
                            }
                        }
                        .play-icon {
                            position: absolute;
                            right: 25px;
                            top: 20px;
                            color: #1890ff;
                            font-size: 28px;
                            transform: translateY(2px);
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    //第三部分
    .main-t {
        min-width: 1440px;
        margin-top: 100px;
        margin-bottom: 100px;
        text-align: center;
        h1 {
            font-size: 32px;
            color: #2a2e32;
            font-weight: 600;
        }
        ul {
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            margin-top: 50px;
            width: 1300px;
            height: 250px;

            li {
                float: left;
                width: 236px;
                height: 100%;
                h4 {
                    margin-top: 32px;
                    font-size: 24px;
                    font-weight: 600;
                    color: #2a2e32;
                }
                p {
                    margin-top: 16px;
                    font-size: 14px;
                    color: #666;
                    text-align: left;
                }
                img {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }
    //第四部分
    .main-four {
        width: 100%;
        min-width: 1440px;
        height: 710px;
        text-align: center;
        color: #2a2e32;
        background: #f7faff;
        h1 {
            padding-top: 50px;
            font-size: 32px;
            font-weight: 600;
            color: #2a2e32;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            width: 1140px;
            margin: 0 auto;
            margin-top: 50px;
            li {
                width: 380px;
                height: 250px;
                text-align: center;
                img {
                    margin-top: 25px;
                }
                h4 {
                    margin-top: 12px;
                    font-size: 20px;
                    font-weight: 600;
                }
                div {
                    margin: 0 auto;
                    margin-top: 25px;
                    width: 172px;
                    text-align: left;
                    p {
                        font-size: 14px;
                        color: #666;
                    }
                }
            }
        }
    }
    //第五部分
    .main-five {
        width: 100%;
        min-width: 1440px;
        margin-top: 100px;
        h1 {
            width: 288px;
            height: 45px;
            margin: 0 auto;
            font-size: 32px;
            font-weight: 600;
            color: #2a2e32;
            margin-bottom: 50px;
        }
        .banner-container {
            position: relative;
            width: 100%;
            min-width: 1440px;
            .main_l {
                position: absolute;
                height: 369px;
                width: 50%;
                min-width: 720px;
                background: url(../../assets/banner/6.jpg) no-repeat;
                background-position: center;
                img {
                    height: 369px;
                }
            }
        }
    }
}
</style>
