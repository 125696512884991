
import { Vue, Component } from 'vue-property-decorator';
import Banner from '@/components/common/banner.vue';
import FootBox from '@/components/common/footBox.vue';
import exampleCard from '@/components/common/swiperCard.vue';

@Component({
    components: {
        Banner,
        FootBox,
        exampleCard,
    },
})
export default class Solution extends Vue {
    leftPicture = '@/assets/banner/1.png';
}
