<template>
    <div class="aboutUs-container">
        <!-- 第一部分 -->
        <div class="main-f">
            <img src="@/assets/aboutUs/联系我们首图.jpg" alt="" />
        </div>
        <!-- 第二部分 -->
        <div class="main-s">
            <div class="main-sl">
                <h2>关于爱因智能</h2>
                <p>
                    广东爱因智能科技有限公司（以下简称“爱因智能”）是一家AI数智化解决方案供应商，
                    经政府认证的高新技术企业、专精特新中小企业、广东省科技型中小企业、创新型中小企业、广州市人工智能企业。
                    爱因智能为亚太地区最大的人工智能和智能语音上市公司科大讯飞（代码：002230）的子公司，
                    自2016年3月10日成立以来，
                    一直致力于企业智能化服务，提供全渠道智能服务体验解决方案，数字营销服务，智能知识库等服务。
                    经过多年项目实战，爱因智能已形成完善的技术沉淀，从产品服务的广度，到算法模型的深度，均在商业应用场景中取得了高价值的回报。
                </p>
            </div>
            <div class="main-sr">
                <img src="@/assets/aboutUs/about3.png" alt="" />
            </div>
        </div>
        <!-- 第三部分 -->
        <div class="main-t">
            <div class="title">
                <h3>所获荣誉：</h3>
                <h1>连续多年在技术，营销，应用服务等领域获得奖项</h1>
            </div>
            <!-- 时间轴 -->
            <div class="time-line-container">
                <div ref="timeLine" class="time-line">
                    <div v-for="(item, index) in timeLine" :key="index" class="line-item">
                        <div class="dot"></div>
                        <div class="content">
                            <p class="time">{{ item.time }}</p>
                            <div class="desc">
                                <div class="desc-dot"></div>

                                <p class="text">
                                    {{ item.desc1 }}
                                </p>
                                <br />
                                <div v-if="item.desc2" class="desc-dot"></div>
                                <p class="text">
                                    {{ item.desc2 }}
                                </p>
                                <br />
                                <div v-if="item.desc3" class="desc-dot"></div>
                                <p class="text">
                                    {{ item.desc3 }}
                                </p>
                                <br />
                                <div v-if="item.desc4" class="desc-dot"></div>
                                <p class="text">
                                    {{ item.desc4 }}
                                </p>
                                <br />
                                <div v-if="item.desc5" class="desc-dot"></div>
                                <p class="text">
                                    {{ item.desc5 }}
                                </p>
                            </div>
                            <p class="desc"></p>
                            <p class="desc"></p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 左右箭头 -->
            <a-button ref="arrowlC" class="arrow-l" :disabled="lbtn ? true : false" @click="prev">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 30"
                    class="design-iconfont"
                    style="width: 13px; margin-top: 2px"
                >
                    <path
                        ref="arrowl"
                        transform="matrix(0 1 -1 0 31.845517 -8.5)"
                        d="M9.7436875 16.9816875L23.1093125 30.0183125 37.2563125 16.9816875"
                        stroke="#fff"
                        stroke-width="2"
                        fill="none"
                        fill-rule="evenodd"
                        stroke-linecap="round"
                    />
                </svg>
            </a-button>
            <a-button ref="arrowrC" class="arrow-r" :disabled="rbtn ? true : false" @click="next">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 30"
                    class="design-iconfont"
                    style="width: 13px; margin-top: 2px"
                >
                    <path
                        ref="arrowr"
                        transform="matrix(0 1 -1 0 31.845517 -8.5)"
                        d="M9.7436875 16.9816875L23.1093125 30.0183125 37.2563125 16.9816875"
                        stroke="#FFF"
                        stroke-width="2"
                        fill="none"
                        fill-rule="evenodd"
                        stroke-linecap="round"
                    />
                </svg>
            </a-button>
        </div>
        <!-- 第四部分 -->
        <div class="main-four">
            <h1>知名企业的信任选择</h1>
            <div class="banner-container">
                <div class="main">
                    <div class="main_l"></div>
                    <Banner></Banner>
                </div>
            </div>
        </div>
        <!-- 第五部分 -->
        <FootBox>
            <template #map>
                <div class="map">
                    <div class="contact">
                        <h1>联系我们</h1>
                        <p>电话： 18825046050</p>
                        <p>座机： 020-37639225</p>
                        <p>邮箱： sywu3@iflytek.com</p>
                        <p style="width: 350px; padding-left: 52px; text-indent: -52px">
                            地址： 广东省广州市海珠区阅江西路218/220号广州国际媒体港西港十层
                        </p>
                    </div>
                </div>
                <!--  <img
          src="@/assets/aboutUs/编组 6 (2).png"
          alt=""
          style="
            width: 100%;
            min-width: 1440px;
            margin-top: 100px;
            margin-bottom: -64px;
          "
        /> -->
            </template>
        </FootBox>
    </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import Banner from '@/components/common/banner.vue';
import FootBox from '@/components/common/footBox.vue';
@Component({
    components: {
        Banner,
        FootBox,
    },
})
export default class Home extends Vue {
    timeLine = [
        {
            time: '2023年',
            desc1: '专精特新中小企业',
            desc2: '总经理孙文礼先生被政协聘请为政协第十四届广州市委员会经济工作专家顾问',
            desc3: '南沙区重点领域虚拟人示范科技项目团队',
            desc4: '入选2023年南沙区重点领域科技技术项目',
            desc5: '上格人气产品奖',
        },
        {
            time: '2022年',
            desc1: '广州市大数据人工智能企业库入库',
            desc2: '高新技术企业',
            desc3: '创新型中小企业',
            desc4: '广州创新力大奖',
        },
        { time: '2021年', desc1: '年度A级纳税人', desc2: '第一批国家中小型科技企业' },
        { time: '2020年', desc1: '第十六届“金耳唛杯”卓越数字服务奖' },
        {
            time: '2019年',
            desc1: '第10届金鼠标数字营销大赛-智能营销类案例铜奖；',
            desc2: '第八届创新创业大赛互联网行业优胜奖；',
            desc3: '创客广东”大数据中小企业创新大赛企业组银奖；',
            desc4: '2019中国（行业）品牌企业典范',
        },
        {
            time: '2018年',
            desc1: '第18届亚太信息通讯科技大奖赛Consumer Digital Marketing金奖；',
            desc2: '“快公司Fast Company ”中国最佳创新公司50强；',
            desc3: '中国广告长城奖AI营销类优秀奖',
        },
        { time: '2016年', desc1: '公司成立' },
    ];
    lnum = 1; //时间轴
    rnum = 1;
    lbtn = true; //左按钮默认不能点击；
    rbtn = false; //右按钮可以点
    winWidth = window.innerWidth || document.body.clientWidth;
    rMinLeft = this.winWidth - 2100; // left的最小值

    prev() {
        let tl = this.$refs.timeLine;
        this.lnum++;
        var step = (this.lnum * 300 - tl.offsetLeft) / 10;
        step = step > 0 ? Math.ceil(step) : Math.floor(step);
        let timeLeft = tl.offsetLeft + step;

        if (tl.offsetLeft >= 100) {
            tl.style.left = 100 + 'px';
            this.lnum = 1;
            this.lbtn = true;
        } else {
            tl.style.left = timeLeft + 'px';
            this.lbtn = false;
        }
        if (timeLeft <= this.rMinLeft) {
            this.rbtn = true;
        } else {
            this.rbtn = false;
        }
    }
    next() {
        let tl = this.$refs.timeLine;
        this.rnum++;
        var step = (-this.rnum * 500 - tl.offsetLeft) / 10;
        step = step > 0 ? Math.ceil(step) : Math.floor(step);
        let timeLeft = tl.offsetLeft + step;
        tl.style.left = timeLeft + 'px';
        if (timeLeft >= 100) {
            this.lbtn = true;
        } else {
            this.lbtn = false;
        }
        if (tl.offsetLeft <= this.rMinLeft) {
            this.rnum = 1;
            tl.style.left = this.rMinLeft + 'px';
            this.rbtn = true;
        } else {
            this.rbtn = false;
        }
    }
}
</script>

<style lang="less" scoped>
.aboutUs-container {
    width: 100%;
    min-width: 1440px;
    // 第一部分
    .main-f {
        position: relative;
        height: 583px;
        width: 100%;
        min-width: 1440px;
        overflow: hidden;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: -1;
            height: 583px;
            transform: translate(-50%, -50%);
            min-width: 1440px;
        }
    }
    // 第二部分
    .main-s {
        position: relative;
        min-width: 1440px;
        height: 480px;
        width: 1100px;
        margin: 0 auto;
        margin-top: 100px;
        padding: 0px 200px 0 140px;
        .main-sl {
            float: left;
            width: 350px;
            text-align: left;
            h2 {
                font-weight: 600;
                font-size: 40px;
                color: #000000;
            }
            p {
                font-size: 16px;
                color: #000000;
                line-height: 34px;
            }
        }
        .main-sr {
            position: absolute;
            top: -90px;
            right: 50px;
            width: 64%;
            img {
                width: 100%;
            }
        }
    }
    // 第三部分
    .main-t {
        position: relative;
        width: 100%;
        min-width: 1440px;
        height: 760px;
        margin-top: 100px;
        margin-bottom: 100px;
        background: #1c7be4;

        .title {
            width: 792px;
            height: 96px;
            margin-left: 285px;
            padding-top: 50px;

            h3 {
                margin: 0px;
                font-size: 28px;
                font-weight: 600;
                color: #ffffff;
            }
            h1 {
                font-size: 32px;
                font-weight: 600;
                color: #ffffff;
            }
        }
        // 时间轴
        .time-line-container {
            margin-top: 150px;
            padding-top: 10px;
            width: 100%;
            overflow: hidden;
            .time-line {
                position: relative;
                left: 100px;
                display: flex;
                width: 2100px;
                border-top: 3px solid #0097fa;
                transition: all 0.5s ease;
                .line-item {
                    position: relative;
                    width: 300px;
                    .dot {
                        position: absolute;
                        top: -10px;
                        z-index: 1;
                        width: 15px;
                        height: 15px;
                        border: 2px solid #0097fa;
                        border-radius: 50%;
                        background: #fff;
                        margin: auto;
                        margin-bottom: -4px;
                    }
                    .content {
                        margin-top: 50px;
                        width: 261px;
                        color: #ffffff;
                        .time {
                            font-size: 24px;
                        }
                        .desc {
                            width: 260px;
                            font-size: 18px;
                            .desc-dot {
                                float: left;
                                width: 5px;
                                height: 5px;
                                margin-top: 12px;
                                border-radius: 50%;
                                background: #fff;
                            }
                            .text {
                                margin-left: 10px;
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
        // 左右箭头
        .arrow-l {
            position: absolute;
            z-index: 1;
            bottom: 5%;
            right: 10%;
            width: 47px;
            height: 47px;
            text-align: center;
            padding-top: 5px;
            border-radius: 10px;
            border: #4563e2 1px solid;
            background: #4563e2;
        }
        .arrow-l:hover {
            border: #458afb 1px solid;
            background: #458afb;
        }
        .arrow-r {
            position: absolute;
            z-index: 1;
            bottom: 5%;
            right: 6%;
            width: 47px;
            height: 47px;
            text-align: center;
            padding-top: 5px;
            border: #4563e2 1px solid;
            background: #4563e2;
            transform: scaleX(-1);
            border-radius: 10px;
        }
        .arrow-r:hover {
            border: #458afb 1px solid;
            background: #458afb;
        }
    }
    //第四部分
    .main-four {
        width: 100%;
        min-width: 1440px;
        height: 500px;
        margin-top: 100px;
        h1 {
            text-align: center;
            height: 45px;
            margin: 0 auto;
            font-size: 32px;
            font-weight: 600;
            color: #2a2e32;
            margin-bottom: 50px;
        }
        .banner-container {
            position: relative;
            width: 100%;
            min-width: 1440px;
            .main_l {
                position: absolute;
                height: 369px;
                width: 50%;
                min-width: 720px;
                background: url(../../assets/banner/8.jpg) no-repeat;
                background-position: center;
                img {
                    height: 369px;
                }
            }
        }
    }
    //第五部分
    .map {
        position: relative;
        width: 100%;
        height: 458px;
        min-width: 1440px;
        margin-top: 100px;
        background: url(../../assets/aboutUs/map.jpg) no-repeat;
        background-position: center;
        .contact {
            position: absolute;
            right: 160px;
            bottom: 50px;
            width: 480px;
            height: 294px;
            padding-top: 35px;
            padding-left: 35px;
            color: #2a2e32;
            background: rgba(255, 255, 255, 0.9);
            border-radius: 4px;
            h1 {
                margin: 0px 0px 25px;
                text-align: left;
                font-size: 32px;
            }
            p {
                margin: 0;
                text-align: left;
                font-size: 16px;
                color: #626262;
                line-height: 29px;
            }
        }
    }
}
</style>
