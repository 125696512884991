
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Banner from '@/components/common/banner.vue';
import FootBox from '@/components/common/footBox.vue';
import swiperCard from '@/components/common/swiperCard.vue';
import { THREE_BOTTOM_LIST, QUE_LIST, ANS_LIST } from '@/utils/v1.2data.js';
import { MODEL_INFO } from '@/utils/v1.2data';
@Component({
    components: {
        Banner,
        FootBox,
        swiperCard,
    },
})
export default class Solution extends Vue {
    @Prop() isLoadingThree;
    publicPath = process.env.APP_VUE_CDN;
    info = MODEL_INFO;
    iframeSrc = '';
    isloading = true;
    threeBottomList = [];
    currentDlg = '正在处理......';
    interTimer = null;
    settimeoutList = [];
    ans_con_r = null;
    ans_con_r2 = null;
    ans_con_q = null;
    ans_btns = null;
    q_text = null;
    currentTab = 1;
    queList = [];
    loadingTimer = null;
    @Watch('isLoadingThree')
    onIsLoadingThree(val) {
        if (val && !this.iframeSrc) {
            this.iframeSrc = `/modelThreeD.html`;
            this.createIframe();
        }
    }
    created() {
        this.threeBottomList = THREE_BOTTOM_LIST;
        this.queList = QUE_LIST;
    }

    mounted() {
        localStorage.setItem('modelLoaded', 'false');
        var s = document.querySelector('.q_text');
        this.q_text = s;
        this.ans_btns = Array.from(document.querySelectorAll('.ans_btns div'));
        this.ans_con_r = document.querySelector('.ans_con_r');
        if (this.currentTab == 1) {
            this.ans_con_r2 = document.querySelector('.ans_con_r2');
        }
        this.ans_con_q = document.querySelector('.ans_con_q');
        this.startAnimate();
    }
    async createIframe() {
        this.setLoadingListener();
        let iframebox = document.querySelector('.iframebox');
        let iframe = document.createElement('iframe');
        iframe.src = this.iframeSrc;
        iframe.height = '100%';
        iframe.width = '100%';
        iframe.scrolling = 'no';
        iframe.frameBorder = '0';
        iframebox.appendChild(iframe);
        await new Promise((resolve) => {
            let t = setTimeout(resolve, 5000);
            this.settimeoutList.push(t);
        });
    }
    async startAnimate() {
        this.ans_con_r.classList.remove('fast');
        this.ans_con_r2.classList.remove('fast');
        this.ans_con_q.classList.remove('fast');
        this.ans_con_r.classList.remove('timerclass');
        this.ans_con_r2.classList.remove('timerclass');
        this.ans_con_q.classList.remove('timerclass');
        this.ans_con_r.classList.add('hover');
        this.ans_con_r2.classList.add('hover');
        this.ans_con_q.classList.add('hover');
        await new Promise((resolve) => {
            let t = setTimeout(resolve, 5500);
            this.settimeoutList.push(t);
        });
        this.clearTimer();
        this.setIntervalTimer();
    }
    setLoadingListener() {
        this.loadingTimer = setInterval(() => {
            let status = localStorage.getItem('modelLoaded');
            if (status === 'true') {
                this.isloading = false;
                clearInterval(this.loadingTimer);
            }
        }, 500);
    }
    clearTimer() {
        this.settimeoutList.forEach((item) => {
            if (item) clearTimeout(item);
        });
        clearInterval(this.interTimer);
    }
    beforeDestroy() {
        this.clearTimer();
        clearInterval(this.loadingTimer);
        this.onReset();
    }
    onReset() {
        this.ans_con_q.classList.remove('ans_con_q2');

        this.q_text.innerHTML = this.currentDlg;
        this.ans_con_r.classList.remove('hover');
        this.ans_con_r2.classList.remove('hover');
        this.ans_con_q.classList.remove('hover'); //无动画

        this.ans_con_r.classList.add('fast');
        this.ans_con_r2.classList.add('fast');
        this.ans_con_q.classList.add('fast'); //消失

        this.ans_con_r.classList.add('timerclass');
        this.ans_con_r2.classList.add('timerclass');
        this.ans_con_q.classList.add('timerclass'); //起始位置
    }
    setIntervalTimer() {
        let list = ANS_LIST;
        let dlg = list[this.currentTab - 1];
        var s = this.q_text;
        var i = 1;
        this.interTimer = setInterval(() => {
            if (s) {
                s.innerHTML = dlg.substring(0, i);
                i++;
                if (s.innerHTML == dlg) {
                    clearInterval(this.interTimer);
                    console.log('文字：完成！');
                }
            }
        }, 200);
    }
    async showText(i) {
        if (i == this.currentTab) return;
        this.clearTimer();
        this.currentTab = i;
        this.ans_btns.forEach((item, idx) => {
            if (idx == i - 1) {
                item.classList.add('current');
            } else {
                item.classList.remove('current');
            }
        });
        this.ans_con_q.classList.add('ans_con_q2');
        this.onReset();
        await new Promise((resolve) => {
            let t = setTimeout(resolve, 1000);
            this.settimeoutList.push(t);
        });
        this.startAnimate();
    }
}
