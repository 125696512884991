import Vue from 'vue';
import Router from 'vue-router';

//导入需要的组件
import Home from '@/components/home.vue';

import Solution from '@/components/solution/solution.vue';
import Robot from '@/components/solution/robot.vue';
import Detection from '@/components/solution/detection.vue';
import Assistant from '@/components/solution/assistant.vue';
import Virtual from '@/components/solution/virtual.vue';
import RichText from '@/components/solution/richText.vue';
import VoiceAssistant from '@/components/solution/voiceAssistant.vue';

import AiAbility from '@/components/aiAbility/aiAbility.vue';
import Natural from '@/components/aiAbility/natural.vue';
import ORCImage from '@/components/aiAbility/ORCImage.vue';
import Knowledge from '@/components/aiAbility/knowledge.vue';
import Writing from '@/components/aiAbility/writing.vue';
import Model from '@/components/aiAbility/model.vue';

import CustomerCase from '@/components/customerCase/customerCase.vue';
import Property from '@/components/customerCase/property.vue';
import Maternal from '@/components/customerCase/maternal.vue';
import CarIndustry from '@/components/customerCase/carIndustry.vue';

import AboutUs from '@/components/aboutUs/aboutUs.vue';

Vue.use(Router);

//配置路由
const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/home',
        },
        {
            path: '/home',
            component: Home,
            meta: {
                title: '首页',
            },
        },
        {
            path: '/solution',
            component: Solution,
            meta: {
                title: '首页',
            },
        },
        {
            path: '/solution/robot',
            component: Robot,
        },
        {
            path: '/solution/detection',
            component: Detection,
        },
        {
            path: '/solution/assistant',
            component: Assistant,
        },
        {
            path: '/solution/richText',
            component: RichText,
        },
        {
            path: '/solution/virtual',
            component: Virtual,
        },
        {
            path: '/solution/voiceAssistant',
            component: VoiceAssistant,
        },
        {
            path: '/aiAbility',
            component: AiAbility,
        },
        {
            path: '/aiAbility/natural',
            component: Natural,
        },
        {
            path: '/aiAbility/ORCImage',
            component: ORCImage,
        },
        {
            path: '/aiAbility/knowledge',
            component: Knowledge,
        },
        {
            path: '/aiAbility/writing',
            component: Writing,
        },
        {
            path: '/aiAbility/model',
            component: Model,
        },
        {
            path: '/customerCase',
            component: () => import('@/components/customerCase/index.vue'),
        },
        {
            path: '/aboutUs',
            component: AboutUs,
        },
    ],
});

//为router实例对象，声明全局前置导航守卫:清除轮播图定时器
// 只要发生了路由的跳转，必然会触发beforeEach 指定的function回调函数
router.beforeEach(function (to, form, next) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    next();
});
export default router;
