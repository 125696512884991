<template>
    <div id="app">
        <div class="tab-bar" :class="{ 'model-tab': isModel }">
            <img v-if="!logo && !isModel" class="logo" src="@/assets/v2/logo-下移.png" alt="" />
            <img v-if="logo && !isModel" class="logo" src="@/assets/homeImg/logo-v2.png" alt="" />
            <img v-if="isModel" class="logo logo-en" src="@/assets/homeImg/logo-en.png" />

            <ul class="guide">
                <li><router-link to="/home" active-class="active">首页</router-link></li>
                <li>
                    <router-link active-class="active" to="/solution">解决方案</router-link>
                    <div class="so-dropdown" style="left: -45px">
                        <ul>
                            <li><router-link to="/solution">智能语音综合服务</router-link></li>
                            <li>
                                <router-link to="/solution/richText">全渠道富文本智能交互</router-link>
                            </li>
                            <li>
                                <router-link to="/solution/virtual">虚拟人智能交互接待</router-link>
                            </li>
                            <li>
                                <router-link to="/solution/detection">语义检测服务</router-link>
                            </li>
                            <li>
                                <router-link to="/solution/voiceAssistant">语音坐席助理</router-link>
                            </li>
                            <!-- <li>
                                <router-link to="/solution/robot">智能导览可交互机器人</router-link>
                            </li> -->
                            <li>
                                <router-link to="/solution/assistant">爱播助手</router-link>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <router-link active-class="active" to="/aiAbility"> AI能力</router-link>
                    <div class="ai-dropdown" style="width: 170px; left: -33px">
                        <ul>
                            <li><router-link to="/aiAbility">语音能力</router-link></li>
                            <li>
                                <router-link to="/aiAbility/natural">自然语言处理</router-link>
                            </li>
                            <li>
                                <router-link to="/aiAbility/ORCImage">OCR图像识别</router-link>
                            </li>
                            <!-- <li><router-link to="/aiAbility/knowledge">知识抽取</router-link></li> -->
                            <!-- <li><router-link to="/aiAbility/writing">智能写作</router-link></li> -->
                            <li><router-link to="/aiAbility/model">AI大语言模型</router-link></li>
                        </ul>
                    </div>
                </li>
                <li>
                    <router-link active-class="active" to="/customerCase">客户案例</router-link>
                </li>
                <li>
                    <router-link active-class="active" to="/aboutUs">关于我们</router-link>
                </li>
            </ul>
        </div>
        <ContactAffix v-if="currentPath != '/home'"></ContactAffix>
        <!-- 定义路由的占位符 -->
        <router-view :is-loading-three="isLoadingThree"></router-view>
    </div>
</template>

<script>
import ContactAffix from '@/components/common/contactAffix.vue';
export default {
    components: {
        ContactAffix,
    },
    data() {
        return {
            scroll: 0,
            logo: true,
            isModel: false,
            currentPath: '',
            isLoadingThree: false,
        };
    },
    watch: {
        $route(to, from) {
            console.log('to.path', to.path);
            this.currentPath = to.path;
            if (to.path == '/aiAbility/model') {
                this.isModel = true;
            } else {
                this.isModel = false;
            }
        },
    },
    mounted() {
        if (this.$route.path == '/aiAbility/model') {
            this.isModel = true;
        } else {
            this.isModel = false;
        }
        window.addEventListener('scroll', this.onScroll);
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        onScroll() {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            const tabBar = document.querySelector('.tab-bar');
            if (scrollTop > 1400) {
                this.isLoadingThree = true;
            } else {
                this.isLoadingThree = false;
            }
            if (scrollTop > 800) {
                this.logo = false;
                tabBar.classList.add('fixed');
                tabBar.style.background = '#fff';
            } else if (scrollTop < 800 && tabBar.classList.contains('fixed')) {
                this.logo = true;
                tabBar.classList.remove('fixed');
                tabBar.style.background = 'rgba(0, 0, 0, 0.8)';
                tabBar.style.border = 'none';
            } else {
                this.logo = true;
                tabBar.classList.remove('fixed');
                tabBar.style.background = 'rgba(0, 0, 0, 0.8)';
                tabBar.style.border = 'none';
            }
        },
    },
};
</script>

<style lang="less">
html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    .fixed {
        position: fixed;
        top: 0px;
        z-index: 3;
        background: rgba(255, 255, 255, 0.38);
        box-shadow: 0 2px 4px 0 #00000017;
        .logo {
            width: 102px;
            height: 39px;
            margin-left: 27px;
        }
        .guide {
            a:hover {
                color: #2b8cff;
            }
            li {
                a {
                    color: #626262;
                }
                li:hover {
                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                        color: #2b8cff !important;
                    }
                }
            }

            .active {
                display: block;
                width: 100%;
                font-weight: bold;
                font-family: Microsoft YaHei, PingFangSC-Semibold, sans-serif;
                border-bottom: 1px solid #0097fa;
                color: #2b8cff !important;
            }
        }
    }
    .tab-barScroll {
        position: fixed;
        top: 0px;
        z-index: 3;
    }
    .tab-bar {
        position: fixed;
        top: 0px;
        z-index: 999;
        width: 100%;
        height: 60px;
        background: rgba(0, 0, 0, 0.8);
        filter: blur(16);
        display: flex;
        align-items: center;
        transition: background 0.3s;
        &.model-tab {
            background: #fff !important;
            color: #000;
            justify-content: space-between;
            .guide {
                margin: 0;
                margin-right: 24px;
            }
            ul,
            li,
            a {
                color: #000;
            }
        }
        .logo {
            width: 211px;
            height: auto;
            margin-left: 27px;
            &-en {
                width: 206px;
                height: 48px;
            }
        }
        .guide {
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 550px;
            height: 66px;
            text-align: center;
            font-size: 16px;
            cursor: pointer;
            transform: translateX(-32px);

            li {
                height: 60px;
                line-height: 60px;
                position: relative;
                z-index: 3;
                width: 100px;
                .so-dropdown,
                .ai-dropdown,
                .cu-dropdown {
                    position: absolute;
                    top: -264px;
                    z-index: -1;
                    display: none;
                    overflow: hidden;
                    width: 190px;
                    padding-bottom: 20px;
                    font-size: 16px;
                    color: #d0d0d0;
                    text-align: center;
                    background: rgba(0, 0, 0, 0.8);
                    transition: all 0.5s ease;

                    li {
                        width: 100%;
                        height: 60px;
                        padding-top: 17px;
                        transition: all 0.1s ease;
                        a {
                            display: block;
                            width: 100%;
                            height: 100%;
                            font-size: 15px;
                            color: #d0d0d0;
                        }
                    }
                    li:hover {
                        a {
                            display: block;
                            width: 100%;
                            height: 100%;
                            color: #2b8cff;
                        }
                    }
                }
                .ai-dropdown {
                    width: 120px;
                }
                .cu-dropdown {
                    width: 100px;
                }
            }
            .active {
                font-family: Microsoft YaHei, PingFangSC-Semibold, sans-serif;
                border-bottom: 2.5px solid #2b8cff;
                color: #2b8cff;
                font-weight: 600;
            }
            li:hover {
                border-bottom: none;
                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    color: #2b8cff;
                }
                .so-dropdown,
                .ai-dropdown,
                .cu-dropdown {
                    top: 60px;
                    display: block;
                    background: rgba(0, 0, 0, 0.8);
                    /*   a {
                        display: block;
                        color: #d0d0d0;
                    } */
                }
            }
        }
    }
}
</style>
